import {useState, useEffect, useCallback} from 'react';



import { ClientsService, ConcessionnaireService } from '../../../common/services';
import {Search, Button} from '../../../components'
import { InboxOutlined } from '@ant-design/icons';
import { Form, message, Tabs, Upload } from 'antd';

const { TabPane } = Tabs;

const AddClients = ({client = null, onComplete = null}) => {

  const initialiseData = () => ({id : 0, nom : '', prenom : '', lcaisseId : '', email : '', username: '', mobile: '', concessionnaires : []});

    const [data, setData] = useState(initialiseData())
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [errors, setErrors] = useState(null)
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();
    const [state, setState] = useState({
      file: ''
    })
  

    const mapToMultiSelectItems = (items) => items?.map(item => ({value : item.id, label : item.code, description : `${item.nom} ${item.prenom}`}))

    useEffect(() => {
        (async () =>  {
          setSearchTerm("")
          if(client) {
            setDisabled(true);
            setData({id : client.id, nom : client.nom, prenom : client.prenom, lcaisseId : client.lcaisseId, email : client.email, username: client.username, mobile: client.mobile, concessionnaires : mapToMultiSelectItems((await ConcessionnaireService.get(client.lcaisseId))) })
          }else{
            setDisabled(false);
            setData(initialiseData());
          }
          })()
    }, [client])


    const isDataValid =  useCallback(() => ['nom', 'prenom', 'lcaisseId', 'email', 'username', 'mobile'].every(field => data[field]), [data])

    const onCancel = () => {
      setSearchTerm("");
      onComplete?.(true);     
    }

    const onChange = (field) => {
      setData(_data => ({..._data, ...field}))
      setErrors(null)
    }

    const draggerProps = {
      maxCount:1,
      onRemove: () => {
        setState((prevState) => ({ ...prevState, file: "" }));
      },
      beforeUpload: (file) => {
        setState((prevState) => ({ ...prevState, file }));
        return false;
      },
      fileList: state.file ? [state.file] : [],
    }

    return (
        <div>
          <Tabs defaultActiveKey="1" onChange={()=> console.log("Change client tab")} key={"kParams"}>
            <TabPane tab="Ajouter un client" key="1" tabKey="params"> 
              {errors && (<div className="form-group login-page__errors">
              { errors?.message ?? ''}
              </div>)}  

                <p>
                    <input placeholder="ID client" 
                    disabled={disabled}
                    value={data.lcaisseId} onChange={(event) => onChange({lcaisseId : event.target.value})} className="form-control" />
              </p>

              <p>
                <input placeholder="Nom"  disabled={disabled}
                value={data.nom} onChange={(event) => onChange({nom : event.target.value})} className="form-control" />
              </p>

              <p>
                <input placeholder="Prenom" disabled={disabled}
                value={data.prenom} onChange={(event) => onChange({prenom : event.target.value})} className="form-control" />
              </p>

              <p>
                <input placeholder="Email"   disabled={disabled}
                value={data.email} onChange={(event) => onChange({email : event.target.value})} className="form-control" />
              </p>

              <p>
                <input placeholder="Nom d'utilisateur"   disabled={disabled}
                value={data.username} onChange={(event) => onChange({username : event.target.value})} className="form-control" />
              </p> 

              <p>
                <input placeholder="Mobile"   disabled={disabled}
                value={data.mobile} onChange={(event) => onChange({mobile : event.target.value})} className="form-control" />
            </p>

              <Search
                selectedItems={data?.concessionnaires || []}
                asyncSearch={async (searchTerm, abort) =>  mapToMultiSelectItems(await ConcessionnaireService.search(searchTerm, abort))}
                onSelect={(items) => setData(_data => ({..._data, concessionnaires : items}))}
                onChange={setSearchTerm}
                {...{disabled, searchTerm}}
            />

            <div className="virgin-button text-right sub-section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr" style={{display : 'flex', justifyContent: 'flex-end'}}>
                <Button type="button" onClick={onCancel}  className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} >Annuler</Button>
              { disabled ? <Button type="button" onClick={() => setDisabled(false)}  className="btn white thin-border btn-default btn-primary virgin-button"  >Modifier</Button> : 
              <Button {...{isLoading}} type="button" onClick={async() => {
                    try{
                      setLoading(true)
                      if(isDataValid()) {
                        await ClientsService.upsert({...data, concessionnaires : data.concessionnaires?.map(concessionnaire => concessionnaire.value)})
                        onComplete?.(false)
                      }else{
                        setErrors({message : "Veuillez renseigner tous les champs ..."})
                      }
                    }
                    catch(error){
                      setErrors(error)
                    }
                    finally{
                      setLoading(false)
                    }
                }} className="btn white thin-border btn-default btn-primary virgin-button" >Valider</Button> }
            </div>
            </TabPane>

            <TabPane tab="Charger la liste des clients" key="2" tabKey="params"> 
              <Form form={form}>
                <Form.Item name="dragger">
                  <Upload.Dragger {...draggerProps} >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Glisser et deposer</p>
                    <p className="ant-upload-hint">
                      Fichier au format excel
                    </p>
                  </Upload.Dragger>
                </Form.Item>

                <div className="virgin-button text-right sub-section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr" style={{display : 'flex', justifyContent: 'flex-end'}}>
                  <Button type="button" onClick={onCancel}  className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} >Annuler</Button>
                  { disabled ? <Button type="button" onClick={() => setDisabled(false)}  className="btn white thin-border btn-default btn-primary virgin-button"  >Modifier</Button> : 
                  <Button {...{isLoading}} type="button" onClick={async() => {
                      
                    form.validateFields().then((value)=> {
                      const formData = new FormData();
                      formData.append('file', state.file);
                      ClientsService.uploadClient(formData).then((resp)=> {
                        setFileList([]);
                        message.success('upload successfully.');
                      }).catch(error=> {

                      })
                      .finally(()=> onComplete?.(false))
                    })

                    // if(fileList.length > 0) {
                    //   const formData = new FormData();
                    //   formData.append('file', fileList[0]);
                    //   console.log(":::: formData : ", formData)
                    //   ClientsService.uploadClient(formData).then((resp)=> {
                    //     console.log(":::: response :", resp)
                    //     setFileList([]);
                    //     message.success('upload successfully.');
                    //   }).catch(error=> {

                    //   })
                    //   .finally(()=> onComplete?.(false))
                      
                    // }else{
                    //   setErrors({message : "Charger un fichier ..."})
                    // }
                  }} className="btn white thin-border btn-default btn-primary virgin-button" >Valider</Button> }
                </div>
              </Form>
              
            </TabPane>
          </Tabs>
    </div>
    )
}



export default AddClients;