import { Col, Form, Input } from 'antd';
import React from 'react';

const PrenomField=({label, disable=false, size='large', xs, md})=> {
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item 
                    label={label ?? ''}
                    name="prenom"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    <Input placeholder='Prénoms' size={size} disabled={disable}/>
                    
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default PrenomField;