import { Col, Form, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { SiteService } from '../../../common/services';
import SelectWithFilter from '../select-with-filter-component';

const SitesField=({label, required = false, disable=false, size='large', xs, md})=> {
    const { Option } = Select;
    const [state, setState]= useState({
        // Sites
        sites: [],
        isSitesLoading: false
    })

    useEffect(()=> {
        loadSites()
    }, [])

    // Charge liste des produits
    const loadSites=()=> {
        setState(state=>({...state, isSitesLoading: true}))
        SiteService.getSites().then((data)=> {
            let _data = !required ? [{id: null, libelle: 'Tous les sites'}, ...(data || [])] :  data || [];
            setState(state=>({...state, sites: _data}))
        })
        .finally(() => setState(state=>({...state, isSitesLoading: false})));
    }

    // const onSiteSelected=(e)=> {
    //     setConcessionnaireRequest({...concessionnaireRequest, siteId: e?.target?.value })
    // }


    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item 
                    label={label ?? ''}
                    name="siteId"
                    rules={[{ required: required, message: "Champ obligatoire" }]}
                >
                    {
                        state?.isSitesLoading ? (<Spin />) : 
                            (
                                <SelectWithFilter 
                                    placeholder='Site'
                                    size={size} 
                                    disable={disable} 
                                    > 
                                        {state.sites.map((site, index)=> (<Option key={index} value={site?.id}>{site?.libelle}</Option>))}                                              
                                </SelectWithFilter>
                            )
                    }
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default SitesField;