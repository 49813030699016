import vente02 from '../../layout/themes/images/icon/vente-02.png'
import money02 from '../../layout/themes/images/icon/money-02.png'
import rechargementTotal02 from '../../layout/themes/images/icon/rechargement-total-02.png'
import { Link } from 'react-router-dom';

function Consultation () {

  return(
    <>
    <div className="container-fluid thedashy animated fadeInUp sub-section menu-module" data-pgc="dashycloser">
      <div className="col-lg-3 col-sm-6 col-xs-6 sub-section col-md-3">
        <div className="bloc" style={{ height: "auto" }}>
          <img
            src={vente02}
            className="img-responsive icon invert"
            data-pgc-field="dashyimg"
          />
          <h3 data-pgc-field="theme">Chiffre d'affaire</h3>
          <p data-pgc-field="description" className="description description_2">
            Consulter l'historique de votre chiffre d'affaire.&nbsp;
          </p>
          <Link className="btn" to="/chiffres-affaires" target="_self">
            Consulter
          </Link>
        </div>
      </div>
      <div className="col-md-3 col-lg-3 col-sm-6 col-xs-6 sub-section">
        <div className="bloc" style={{ height: "auto" }}>
          <img
            src={money02}
            className="img-responsive icon invert"
            data-pgc-field="dashyimg"
          />
          <h3 data-pgc-field="theme">Opérations</h3>
          <p data-pgc-field="description" className="description description_2">
          Consulter l'historique de vos opérations.&nbsp;
          </p>
          <Link className="btn" to="/operations" target="_self">
            Consulter
          </Link>
        </div>
      </div>
      <div className="col-md-3 col-lg-3 col-sm-6 col-xs-6 sub-section">
        <div className="bloc" style={{ height: "auto" }}>
          <img
            src={rechargementTotal02}
            className="img-responsive icon invert"
            data-pgc-field="dashyimg"
          />
          <h3 data-pgc-field="theme">Commissions</h3>
          <p data-pgc-field="description" className="description description_2">
          Consulter l'historique de vos commissions.&nbsp;
          </p>
          <Link className="btn" to="/commissions" target="_self">
            Consulter
          </Link>
        </div>
      </div>
    </div>
    </>

  )
 
}


export default Consultation;