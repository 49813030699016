
import { useContext, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UseAuthentification } from '../../../common/hooks';
import { DashBoardService, ConcessionnaireService, ClientsService } from '../../../common/services';
import { AppContext } from '../../../common/constants';

import { LoadableContent } from '../../../components';
import {NumberHelper, DateHelper} from '../../../common/helper';

import Green02Image from  '../../../layout/themes/images/icon/green_set_ph-02.png';
import Money02Image from  '../../../layout/themes/images/icon/Money-set-02.png';
import NewSetImage from  '../../../layout/themes/images/icon/newset-02.png';
import SetUed02Image from '../../../layout/themes/images/icon/set_ued-02.png';



const Header = () => {

const auth = UseAuthentification();
  const { id : clientId } = useParams();
  const {store, dispatcher} = useContext(AppContext)
  const [dashboard, setDashboard] = useState({})
  const [concessionnaires, setConcessionnaires] = useState([]);
  const [client, setClient] = useState(null);
  
  const [isLoading, setLoading] = useState(false);


  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])

    useEffect(()=> {
       if(store?.concessionnaire?.id) {
        setLoading(true)
        DashBoardService.getResume({debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()}, store?.concessionnaire?.id).then(setDashboard).finally(() => setLoading(false))
       }
    }, [store?.concessionnaire?.id, date])

    useEffect (() => {
      ConcessionnaireService.get(clientId).then(setConcessionnaires)
    }, [clientId])

    useEffect (() => {
      ClientsService.get(clientId).then(setClient)
    }, [clientId])

    useEffect(() => {
        if(!store?.concessionnaire?.id && concessionnaires?.length) dispatcher({concessionnaire : concessionnaires[0]})
    }, [concessionnaires])


    return (
        <>
        <section style={{backgroundColor: '#09C', color: '#FFF', backgroundImage: 'url("/images/mainback.jpg")', backgroundSize: 'cover', padding: '1px'}} id="service" className="text-center bg-gray theme-maincolorback-alternative theme-maincolorback-dark animated fadeIn home-section" data-pgc="dashboard_header"> 
          <div className="no-padding-section container-fluid home-section-content full-height">
            <div className="sub-section container-fluid full-height" style={{overflow: 'hidden', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}> 
              <div className="animatedParent col-sm-12 col-xs-12 col-lg-6 col-md-6 home-section-user sub-section">
                <h6 className="white breadcrumb" data-pgc-field="dashboard_header_page-title">Client:</h6>
                <h1 className="white dashboard-title theme-text-thin transition-soft" >{`${client?.prenom ?? ''} ${client?.nom ?? ''}`} </h1>
                <div className="white dashboard-matricule" style={{marginTop: '0px', display : 'flex'}} data-pgc-field="dashboard_header_page-title">
                  <select className="form-control main-layout__form-control__select"  
                  value={store?.concessionnaire?.id} onChange={(event)=> dispatcher({concessionnaire : concessionnaires.find(concessionnaire => concessionnaire.id.toString() === event.target.value)})
                    } style={{fontSize: '13px', marginRight : '10px'}}> 
                  {
                    concessionnaires.map(concessionnaire => <option key={concessionnaire.id} value={concessionnaire.id}>{concessionnaire.code}</option>)
                  }                                               
                  </select>
                  <input type="month" value={store.month} onChange={(event) => dispatcher({month : event.target.value})} className="form-control main-layout__form-control__input"  max={DateHelper.getMonthAndYear()}  />
                </div>
                <p className="animated text-left" />
              </div>
              <div className="animatedParent col-sm-12 col-xs-12 col-lg-6 col-md-6 home-section-amount-resume sub-section">
                <h6 className="transition-soft breadcrumb" data-pgc-field="dashboard_header_page-title" style={{ fontSize: '16px' }}>SOLDE AU {new Date().toLocaleString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</h6>
                <h1 className="white bold dashboard-amount-preview transition-soft"> {NumberHelper.formatWithThousandsSeparator(dashboard.solde)} <span className="white" style={{marginTop: '0px'}} data-pgc-field="dashboard_header_page-title">FCFA</span></h1>
                <h3 className="white text-left" style={{marginTop: '0px'}} data-pgc-field="dashboard_header_page-title"> <span className="text-bold hidden">Code concessionnaire</span> </h3>
                <p className="animated text-left" />
                <p className="gc-bloc-sub-header white text-left animated animatedParent_fadeIn hidden" style={{textAlign: 'left!important', paddingLeft: '0px', paddingRight: '1px'}}>USAID is the world's premier international development agency and a catalytic actor driving development results. USAID's work advances U.S. national security and economic prosperity, demonstrates American generosity, and promotes a path to recipient self-reliance and resilience.</p> 
              </div>                                     
            </div>
          </div>                             
        </section>
        <LoadableContent {...{isLoading}}>
        <section style={{color: '#FFF'}} id="service" className="home-section-responsive text-center bg-gray theme-maincolorback-dark theme-maincolorback-alternative hidden"> 
            <div className="container-fluid">
              <div className="full-height sub-section container-fluid" style={{backgroundImage: 'url("images/background/module_bck-01.png")', overflow: 'hidden', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}> 
                <div className="animatedParent col-sm-12 col-xs-12 col-lg-6 col-md-6 home-section-user">
                  <h6 className="white" data-pgc-field="dashboard_header_page-title">Tableau de bord&nbsp;</h6>
                  <h4 className="white dashboard-title theme-text-thin" style={{marginBottom: '0px'}}>Bienvenue <span className="bold">{`${auth?.currentUser?.prenom} ${auth?.currentUser?.nom} `} </span></h4>
                  <h4 className="white dashboard-matricule" style={{marginTop: '0px'}} data-pgc-field="dashboard_header_page-title"><span className="text-bold hidden">Code concessionnaire</span> <span>e10251</span></h4>
                  <p className="animated text-left" />
                  <p className="gc-bloc-sub-header white text-left animated animatedParent_fadeIn hidden" style={{textAlign: 'left!important', paddingLeft: '0px', paddingRight: '1px'}}>USAID is the world's premier international development agency and a catalytic actor driving development results. USAID's work advances U.S. national security and economic prosperity, demonstrates American generosity, and promotes a path to recipient self-reliance and resilience.</p> 
                </div>
                <div className="animatedParent col-sm-12 col-xs-12 col-lg-6 col-md-6 home-section-amount-resume sub-section">
                  <h6 style={{marginTop: '0px', marginBottom: '0px'}} data-pgc-field="dashboard_header_page-title">SOLDE AU {new Date().toLocaleString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</h6>
                  <h4 className="white bold dashboard-amount-preview" style={{marginBottom: '0px', marginTop: '0px'}}>{dashboard?.solde ?? 0} <span className="white" style={{marginTop: '0px'}} data-pgc-field="dashboard_header_page-title">FCFA</span></h4>
                  <h3 className="white text-left" style={{marginTop: '0px'}} data-pgc-field="dashboard_header_page-title"><span className="text-bold hidden">Code concessionnaire</span> </h3>
                  <p className="animated text-left" />
                  <p className="gc-bloc-sub-header white text-left animated animatedParent_fadeIn hidden" style={{textAlign: 'left!important', paddingLeft: '0px', paddingRight: '1px'}}>USAID is the world's premier international development agency and a catalytic actor driving development results. USAID's work advances U.S. national security and economic prosperity, demonstrates American generosity, and promotes a path to recipient self-reliance and resilience.</p> 
                </div>                                     
              </div>
            </div>                             
        </section>
        <div  data-pgc-define="Slider_dash" data-pgc-define-name="Slider_dash" data-pgc-edit="Slider_dash_content">
          <div className="container-fluid theme-maincolorback-light dash-main sub-section">
            <samp className="sub-section hidden" data-pgc-optional="slider_dash_opt">This text is meant to be treated as sample output from a computer program.</samp>
            <div className="no-padding-section container-fluid">
              <div>
                <div id="owl-demo-3-slides" className="owl-carousel">
                  <div className="item no-padding-section bg-white fadeInRight animated theme-animate-delay-01s" style={{paddingTop: '0px !important'}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 no-padding-section col-xs-12 full-height">
                      <div className="container-fluid content-block team-1 team-1-1 shadow-6 animated fadeIn theme-animate-delay-02s full-height no-padding-section" style={{backgroundRepeat: 'no-repeat', backgroundColor: '#ffffff', backgroundImage: 'url("images/background/module_bck-02.png")', backgroundSize: 'cover'}}>
                        <div className="team-member text-center full-height">
                          <div className="no-padding-section full-height col-lg-8 col-md-8 col-sm-8 col-xs-8 sub-section data-place block-color-green" >
                            <p className="text-left dashboard-session ellipsis">NOMBRE DE TERMINAUX</p>
                            <div className="team-details team-member text-left no-padding-section">
                              <h3 className="numbered data">{dashboard?.nbreTerminaux ?? 0}</h3>
                              <p className="text-left prize dashboard-session">Terminaux</p>
                              {/* /.social */}
                            </div>                                                                 
                          </div>
                          <div className="full-height col-lg-4 col-md-4 col-sm-4 col-xs-4 icon_place sub-section">
                            <img src={Green02Image} className="preview_icon" alt="Member Image" /> 
                          </div>
                        </div>
                      </div>                                                     
                    </div>
                  </div>
                  <div className="item no-padding-section bg-white fadeInRight animated theme-animate-delay-01s" style={{paddingTop: '0px !important'}} data-pgc="dash_item">
                    <div className="col-lg-12 col-md-12 col-sm-12 no-padding-section col-xs-12 full-height">
                      <div className="container-fluid content-block team-1 team-1-1 shadow-6 animated fadeIn theme-animate-delay-02s full-height no-padding-section" style={{backgroundRepeat: 'no-repeat', backgroundColor: '#ffffff', backgroundImage: 'url("images/background/module_bck-02.png")', backgroundSize: 'cover'}}>
                        <div className="team-member text-center full-height">
                          <div className="no-padding-section full-height col-lg-8 col-md-8 col-sm-8 col-xs-8 sub-section data-place block-color-yellow" data-pgc-field="color_set">
                            <p className="text-left dashboard-session ellipsis" data-pgc-field="dash_item_title">TOTAL DES RECHARGEMENTS</p>
                            <div className="team-details team-member text-left no-padding-section">
                              <h3 className="black numbered data" data-pgc-field="dash_item_data">{NumberHelper.formatWithThousandsSeparator(dashboard?.rechargement ?? 0)}</h3>
                              <p className="text-left prize dashboard-session" data-pgc-field="dash_item_desc">FCFA</p>
                              {/* /.social */}
                            </div>                                                                 
                            <p className="ellipsis dashboard_subsession text-right" style={{fontSize: '08px !important'}} data-pgc-field="dash_item_subtitle">Période de {date.toDay.toLocaleString("fr-FR", {  year: 'numeric', month: 'long' })}</p>
                          </div>                                                             
                          <div className="full-height col-lg-4 col-md-4 col-sm-4 col-xs-4 icon_place sub-section">
                            <img src={Money02Image} className="preview_icon" alt="Member Image" data-pgc-field="dash_item_icon" /> 
                          </div>
                        </div>
                      </div>                                                     
                    </div>
                  </div>                 
                  <div className="item no-padding-section bg-white fadeInRight animated theme-animate-delay-01s" style={{paddingTop: '0px !important'}} data-pgc="dash_item">
                    <div className="col-lg-12 col-md-12 col-sm-12 no-padding-section col-xs-12 full-height">
                      <div className="container-fluid content-block team-1 team-1-1 shadow-6 animated fadeIn theme-animate-delay-02s full-height no-padding-section" style={{backgroundRepeat: 'no-repeat', backgroundColor: '#ffffff', backgroundImage: 'url("images/background/module_bck-02.png")', backgroundSize: 'cover'}}>
                        <div className="team-member text-center full-height">
                          <div className="no-padding-section full-height col-lg-8 col-md-8 col-sm-8 col-xs-8 sub-section data-place block-color-cold-green" data-pgc-field="color_set">
                            <p className="text-left dashboard-session ellipsis" data-pgc-field="dash_item_title">CHIFFRE D'AFFAIRES</p>
                            <div className="team-details team-member text-left no-padding-section">
                              <h3 className="black numbered data" data-pgc-field="dash_item_data">
                                {NumberHelper.formatWithThousandsSeparator((dashboard?.chiffreAffaire ?? 0) - (dashboard?.annulation ?? 0))}
                              </h3>
                              <p className="text-left prize dashboard-session" data-pgc-field="dash_item_desc">FCFA</p>
                              {/* /.social */}
                            </div>                                                                 
                            <p className="ellipsis dashboard_subsession text-right" style={{fontSize: '8px !important'}} data-pgc-field="dash_item_subtitle">Période de {date.toDay.toLocaleString("fr-FR", {  year: 'numeric', month: 'long' })}</p>
                          </div>                                                             
                          <div className="full-height col-lg-4 col-md-4 col-sm-4 col-xs-4 icon_place sub-section">
                            <img src={SetUed02Image} className="preview_icon" alt="Member Image" data-pgc-field="dash_item_icon" /> 
                          </div>
                        </div>
                      </div>                                                     
                    </div>
                  </div>
                  <div className="item no-padding-section bg-white fadeInRight animated theme-animate-delay-01s" style={{paddingTop: '0px !important'}} data-pgc="dash_item">
                    <div className="col-lg-12 col-md-12 col-sm-12 no-padding-section col-xs-12 full-height">
                      <div className="container-fluid content-block team-1 team-1-1 shadow-6 animated fadeIn theme-animate-delay-02s full-height no-padding-section" style={{backgroundRepeat: 'no-repeat', backgroundColor: '#ffffff', backgroundImage: 'url("images/background/module_bck-02.png")', backgroundSize: 'cover'}}>
                        <div className="team-member text-center full-height">
                          <div className="no-padding-section full-height col-lg-8 col-md-8 col-sm-8 col-xs-8 sub-section data-place block-color-orange" data-pgc-field="color_set">
                            <p className="text-left dashboard-session ellipsis" data-pgc-field="dash_item_title">&nbsp;PAIEMENTS</p>
                            <div className="team-details team-member text-left no-padding-section">
                              <h3 className="black numbered data" data-pgc-field="dash_item_data">{NumberHelper.formatWithThousandsSeparator(dashboard?.paiement ?? 0)}</h3>
                              <p className="text-left prize dashboard-session" data-pgc-field="dash_item_desc">XOF</p>
                              {/* /.social */}
                            </div>                                                                 
                            <p className="ellipsis dashboard_subsession text-right" style={{fontSize: '08px !important'}} data-pgc-field="dash_item_subtitle">Période de {date.toDay.toLocaleString("fr-FR", {  year: 'numeric', month: 'long' })}</p>
                          </div>                                                             
                          <div className="full-height col-lg-4 col-md-4 col-sm-4 col-xs-4 icon_place sub-section">
                            <img src={NewSetImage} className="preview_icon" alt="Member Image" data-pgc-field="dash_item_icon" /> 
                          </div>
                        </div>
                      </div>                                                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </LoadableContent>
        </>
    )
}

export default Header;