import leftLongarrow from '../../layout/themes/images/icon/left-long-arrow-03.png'


import './modal.component.scss'

const Modal = ({children, title, isOpen, onClose}) => {
    return (
        <div className={`modal pg-show-modal pad0 row full-height ${isOpen ? 'in' : ''}`}  tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="false" data-keyboard="true" data-pgc="helpmodal" data-pgc-field="helpmodal" style={{marginRight: '0px', height: '100%', overflow: 'hidden', display: isOpen ? 'block' : 'none'}}>
            <div className="modal-dialog modal-sm modal-lg margin-top0 full-height bg-transparent" style={{height: '100%', width: '100%', marginTop: '-1px'}}>
                <div className="modal-content no-shadow bg-transparent pad0 full-height" style={{marginLeft: '-50px'}}>
                    <div className="modal-body no-border pad0 full-height" style={{marginBottom: '-55px', padding: '0px', marginTop: '0px'}}>
                        <div className="col-xs-1 col-sm-4 full-height col-lg-8 col-md-8" />
                        <div className="col-sm-8 col-xs-11 margin-top0 bg-white full-height col-lg-4 col-md-4 animated fadeInRight animated-very-fast theme-maincolorback-light no-padding-section" style={{boxShadow: 'rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px', backgroundColor: 'white', overflow: 'auto'}}>
                            <div className="container-fluid full-height" style={{padding: '0px'}}><div className="container-fluid thin-border-bottom pad-bottom0 bg-transparent" style={{padding: '0px'}}><div className="container-fluid no-padding-section">
                                <div className="modal-header no-border" style={{borderRadius: '0px', display: 'flex', alignContent: 'center'}}>
                                    <button onClick={onClose}  className="btn btn-default pull-left no-border mylonaci-modal__link-close" style={{marginRight : '10px'}}>
                                        <img src={leftLongarrow}  className="invert mylonaci-modal__link-close__icon" />
                                    </button>
                                    <h3 style={{fontWeight : 'bolder'}}>{title}</h3>
                                </div>
                            </div>
                        <div className="text-left container-fluid sub-section full-height">
                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 main_centering_content no-padding-section" id="main_centering_content">
                        {children}
                   </div>
                   </div>
                   </div>
            </div>
        </div>
     </div>
    </div>
    </div>
</div>
  )
}

export default Modal;