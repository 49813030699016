export default {
 getMonthFirstAndLastDate : (date  = new Date())  => {
    const _date = new Date(date)
    return {firstDay : new Date(_date.getFullYear(), _date.getMonth(), 1), lastDay : new Date(_date.getFullYear(), _date.getMonth() + 1, 0), toDay : _date}
  },
  getMonthAndYear : (date  = new Date()) =>  {
    const [year, month] = (new Date(date)).toISOString(). substring(0, 10).split('-');
     return `${year}-${month}`;
  },
  format : (date) =>  new Date(date).toLocaleString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric' })
}