import {useState, useEffect, useCallback} from 'react';

import {USER_ROLE} from '../../../common/constants'
import { OperateursService } from '../../../common/services';
import { Button} from '../../../components'

const AddOperateurs = ({operateur = null, onComplete = null}) => {

  
  const initialiseData = () => ({id : 0, nom : '', prenom : '',  email : '', username: '', mobile: '', role : USER_ROLE.OPERATEUR})

    const [data, setData] = useState(initialiseData())
    const [isLoading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        (async () =>  {
          if(operateur) {
            setDisabled(true);
            setData({id : operateur.id, nom : operateur.nom, prenom : operateur.prenom,  email : operateur.email, username: operateur.username, mobile: operateur.mobile, role : operateur.role })
          }else{
            setDisabled(false);
            setData(initialiseData());
          }
          })()
    }, [operateur])


    const isDataValid =  useCallback(() => ['nom', 'prenom',  'email', 'username', 'mobile', 'role'].every(field => data[field]), [data])

    const onChange = (field) => {
      setData(_data => ({..._data, ...field}))
      setErrors(null)
    }

    return (
        <div>  
           {errors && (<div className="form-group login-page__errors">
           { errors?.message ?? ''}
          </div>)}   
           <p>
             <input placeholder="Nom"  disabled={disabled}
            value={data.nom} onChange={(event) => onChange({nom : event.target.value})} className="form-control" />
          </p>

          <p>
             <input placeholder="Prenom" disabled={disabled}
            value={data.prenom} onChange={(event) => onChange({prenom : event.target.value})} className="form-control" />
          </p>

          <p>
            <input placeholder="Email"   disabled={disabled}
            value={data.email} onChange={(event) => onChange({email : event.target.value})} className="form-control" />
          </p>

          <p>
            <input placeholder="Nom d'utilisateur"   disabled={disabled}
            value={data.username} onChange={(event) => onChange({username : event.target.value})} className="form-control" />
          </p> 

          <p>
            <input placeholder="Mobile"   disabled={disabled}
            value={data.mobile} onChange={(event) => onChange({mobile : event.target.value})} className="form-control" />
         </p>
         <p>
          <select disabled={disabled}  value={data.role} className="form-control" onChange={(event) => onChange({role : event.target.value})}>                                                             
                <option value={USER_ROLE.OPERATEUR} >OPERATEUR</option>                                                           
                <option value={USER_ROLE.ADMIN}>ADMIN</option>                                                                      
            </select>
         </p>

         <div className="virgin-button text-right sub-section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr" style={{display : 'flex', justifyContent: 'flex-end'}}>
            <Button type="button" onClick={() => onComplete?.(true)}  className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} >Annuler</Button>
           { disabled ? <Button type="button" onClick={() => setDisabled(false)}  className="btn white thin-border btn-default btn-primary virgin-button"  >Modifier</Button> : 
           <Button {...{isLoading}} type="button" onClick={async() => {
                try{
                  setLoading(true)
                   if(isDataValid()) {
                    await OperateursService.upsert({...data, concessionnaires : data.concessionnaires?.map(concessionnaire => concessionnaire.value)})
                    onComplete?.(false);
                  }else{
                    setErrors({message : "Veuillez renseigner tous les champs ..."})
                  }
                }
                catch(error){
                  setErrors(error)
                }
                finally{
                  setLoading(false)
                }
            }} className="btn white thin-border btn-default btn-primary virgin-button" >Valider</Button> }
        </div>
    </div>
    )
}



export default AddOperateurs;