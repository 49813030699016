import React, { useEffect, useState } from 'react';
import Layout from '../layout/layout.page';
import { ReportingService } from '../../common/services';
import { NumberHelper } from '../../common/helper';
import { b64toBlob } from '../../common/helper/file.helpers';
import FileSaver from 'file-saver';
import { Button, Card, Col, Form, Pagination, Row, Segmented, Table } from 'antd';
import ProductsField from '../../components/forms/fields/ProductsField';
import SitesField from '../../components/forms/fields/SiteField';
import UidField from '../../components/forms/fields/UidField';
import CustomAntdForm from '../../components/forms/custom-antd-form';
import CodeSelectField from '../../components/forms/fields/CodeSelectField';
import DateRangeField from '../../components/forms/fields/DateRangeField';
import CodeTerminaux from '../../components/forms/fields/CodeTerminaux';

const chiffreAffairePayload = {
    uid: null,
    codeConcessionnaire: null,
    numeroTerminal: null,
    produitId: null,
    siteId: null,
    sDate: null,
    eDate: null
}

const ReportingChiffreAffaire=()=> {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [exportType, setExportType] = useState('pdf');
    const [currentPage, setCurrentPage] = useState(1);
    const [chiffreAffaires, setChiffreAffaires] = useState([]);  
    const [uidSelected, setUidSelected] = useState(null);
    const [chiffreAffiresRequest, setChiffreAffairesRequest] = useState(chiffreAffairePayload);
    const [codeConcessionnaireSelected, setCodeConcessionnaireSelected]= useState(null);
    

    const [state, setState]= useState({
        // Product
        products: [],
        isProductLoading: false,

        // Sites
        sites: [],
        isSitesLoading: false,

        isExporting: false,

        // Pagination
        pageNo: 0,
        pageSize: 0,
        totalElements: 0,
        totalPages: 0,
        last: 0
    })

    useEffect(() => {
        loadChiffreAffaires(chiffreAffiresRequest, currentPage)
    }, [])

    const loadChiffreAffaires=(request, page)=> {
        setLoading(true)
        ReportingService.getChiffreAffairess(request, {page: page - 1 , size: 10} ).then((response)=> {
            console.log(":::: response : ", response)
            setState(state=> ({
                ...state,
                pageNo: response?.pageNo,
                pageSize: response?.pageSize,
                totalElements: response?.totalElements,
                totalPages: response?.totalPages,
                last: response?.last
            }))
            
            setChiffreAffaires(response?.data || [])
        }).finally(() => setLoading(false));
    }

    const download=()=> {
        setState(state=> ({...state, isExporting: true}))
    
        ReportingService.downloadChiffreAffaires({...chiffreAffiresRequest, format: exportType})
        .then((response) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
            if(response?.bytes) {
                const b64Data = response?.bytes;
                const blob = b64toBlob(b64Data, response?.format === exportType ? 'data:application/vnd.ms-excel;' : 'application/pdf');
                FileSaver.saveAs(blob, response?.fileName)
            }
            else {
                alert("Echec de telechargement ...")
            }
        })
        .finally(() => setState(state=> ({...state, isExporting: false })));
    }
    
    const refreshFilter=(init=false)=> {
        if(init) {
            form.setFieldsValue({
                uid: null, 
                code: null,
                produitId: null,
                siteId: null,
                codeTerminal: null,
                rangeDate: null
            })
            setChiffreAffairesRequest(chiffreAffairePayload)
            loadChiffreAffaires(chiffreAffairePayload, currentPage)
        }
        else {
            form.validateFields().then((value)=> {
                console.log(":::::: values :", value)
                const rangeDate = value?.rangeDate;
                const startDate = rangeDate[0]?.format("YYYY-MM-DD");
                const endDate   = rangeDate[1]?.format("YYYY-MM-DD");
                const chiffreAffairesRequest = {
                    uidConcessionnaire: value?.uid, 
                    codeConcessionnaire: value?.code,
                    produitId: value?.productId,
                    siteId: value?.siteId,
                    numeroTerminal: value?.codeTerminal,
                    sDate: startDate,
                    eDate: endDate
                }
                setChiffreAffairesRequest(chiffreAffairesRequest)
                loadChiffreAffaires(chiffreAffairesRequest, currentPage)
            })
            .catch(error => {
                console.log(":::: Error dans les champs de filtre")
            })
        }
    }

    const onPageChange = (page) => {
        console.log(":::: onPageChange ", page)
        setCurrentPage(page);
        loadChiffreAffaires({...chiffreAffiresRequest}, page);
    };
    const onShowSizeChange = (current) => {
        loadChiffreAffaires({...chiffreAffiresRequest}, current);
    };

    const onChangeSegmented=(e)=> {
        setExportType(e)
    }

    const onChage=(e)=> {
        console.log('Code terminaux :', e)
    }

    const columns = [
        {
            title: "UID",
            dataIndex: "uidConcessionnaire",
            key: "uidConcessionnaire",
            render:(item, chiffreAffaire)=> (chiffreAffaire?.uidConcessionnaire ?? '-')
        },
        {
            title: "Code concessionnaire",
            dataIndex: "codeConcessionnaire",
            key: "codeConcessionnaire"
        },
        {
            title: "Concessionnaire",
            dataIndex: "concessionnaire",
            key: "concessionnaire"
        },
        {
            title: "Produit",
            dataIndex: "produit",
            key: "produit"
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
        },
        {
            title: "Terminal",
            dataIndex: "numeroTerminal",
            key: "numeroTerminal",
        },
        {
            title: "Date",
            dataIndex: "numeroTerminal",
            key: "numeroTerminal",
            render : (item, chiffreAffaire) =>  (new Date(chiffreAffaire.date).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' }))
        },
        
        {
            title: "Chiffre d'affaire",
            dataIndex: "chiffreAffaire",
            key: "chiffreAffaire",
            align: 'right',
            render : (item, chiffreAffaire) =>  (`${NumberHelper.formatWithThousandsSeparator(chiffreAffaire.chiffreAffaire)}`)
            
        },
        {
            title: "Annulation",
            dataIndex: "annulation",
            key: "annulation",
            align: 'right',
            render : (item, chiffreAffaire) =>  (`${NumberHelper.formatWithThousandsSeparator(chiffreAffaire.annulation)}`)
        },
        {
            title: "Remboursement",
            dataIndex: "remboursement",
            key: "remboursement",
            align: 'right',
            render : (item, chiffreAffaire) =>  (`${NumberHelper.formatWithThousandsSeparator(chiffreAffaire.remboursement)}`)
        },
        {
            title: "Paiement",
            dataIndex: "paiement",
            key: "paiement",
            align: 'right',
            render : (item, chiffreAffaire) =>  (`${NumberHelper.formatWithThousandsSeparator(chiffreAffaire.paiement)}`)
        }
        
    ];

    return (
        <React.Fragment>
            <Layout>
                <div className="appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 10px'}}>
                    <p data-pgc-field="appsv3-admin-bloc-title"> Liste des chiffres d'affaires </p> 
                    <div style={{display:'flex', justifyContent: 'end'}}>
                        <div style={{height: '9px'}}>
                            <Segmented size='large' options={[{ label: 'PDF', value: 'pdf' }, { label: 'Excel', value: 'xls' }]} onChange={onChangeSegmented}/>
                        </div>
                        <Button  
                            onClick={() => download()} 
                            loading={state?.isExporting} 
                            style={{borderColor: '#000', margin: '0px 10px'}} 
                            disabled={chiffreAffiresRequest?.sDate === null || chiffreAffiresRequest?.eDate === null ||  chiffreAffiresRequest?.produitId === null ||  chiffreAffiresRequest?.siteId === null }
                            type='success' 
                            size='large' block>
                            <span style={{color: '#fff !important'}}>Exporter</span>
                            </Button>
                        {/* <button type="button" style={{padding: '15px 30px'}} onClick={() => {
                            download("xls")
                        }} className="btn white thin-border btn-default btn-success virgin-button"  >
                            {state.isLoadingXls && (<Loader className={'loadable-content__content__loader'}  style={{height:15, width: 15, marginRight: 10}} /> ) } 
                            <span style={{fontSize:12, color: '#fff'}}>XLS</span></button>

                        <button type="button" style={{padding: '15px 30px', marginLeft: '5px', marginRight:"10px"}} onClick={() => {
                            download("pdf")
                        }} className="btn white thin-border btn-default btn-success virgin-button"  >
                            {state.isLoadingPdf && (<Loader className={'loadable-content__content__loader'} style={{height:15, width: 15, marginRight: 10}} /> ) } 
                            <span style={{fontSize:12, color: '#fff'}}>PDF</span></button> */}
                    </div>
                </div>
                <Card>
                    <CustomAntdForm form={form}>
                        <Row gutter={8} >
                            <ProductsField required={true} label={'Produit'} md={4}/> 
                            <SitesField  label={'Sites'} md={4}/>
                            <UidField label={'UID'} onUIDSelected={setUidSelected}/>
                            <CodeSelectField 
                                label='Code concessionnaire'
                                placeholder='Selectionner code'
                                uuid={uidSelected}
                                onCodeSelected={setCodeConcessionnaireSelected}
                                disable={true}
                                md={4}/>
                            <CodeTerminaux 
                                label={'Code terminaux'}
                                placeholder={'Terminaux'}
                                codeConcessionnaire={codeConcessionnaireSelected} 
                                md={4} />
                            <DateRangeField label={('Periodes')} required={true} md={4}/>

                            <Col className="gutter-row" xs={3} md={3} style={{paddingTop: 40}}>
                                <div style={{display: 'flex'}}>
                                    <Button  onClick={() => refreshFilter()}  style={{borderColor: '#000'}} type='success' size='large' block >
                                        Rafraichir</Button>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={3} md={3} style={{paddingTop: 40}}>
                                <div style={{display: 'flex'}}>
                                    <Button  onClick={() => refreshFilter(true)}  style={{borderColor: '#000'}} type='success' size='large' block >
                                        Initialiser</Button>
                                </div>
                            </Col>
                        </Row>
                    </CustomAntdForm>
                    <Table 
                        columns={columns} 
                        dataSource={chiffreAffaires || []} 
                        loading={isLoading}
                        // pagination={{ position: ["topRight"], pageSize: state?.pageSize }}
                        pagination={false}
                        />
                    <div style={{ textAlign: 'center' }}>
                        <Pagination current={currentPage} onChange={onPageChange} defaultPageSize={10} total={state.totalElements} showSizeChanger onShowSizeChange={onShowSizeChange} />
                    </div>
                </Card>
            </Layout>
        </React.Fragment>
    )
}

export default ReportingChiffreAffaire;