import React from 'react';
import { Col, DatePicker, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';
import 'dayjs/locale/fr'
import dayjs from 'dayjs';

const DateRangeField=({label, required = false, size='large', xs, md})=> {
    const { RangePicker } = DatePicker;

    // Can not select days before today and today
    const disabledNextDate = (current) => current && current > dayjs().endOf('day');

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    name="rangeDate" 
                    label={label ?? ''}
                    rules={[{ required: required, message: "Champ obligatoire" }]}
                >
                    <RangePicker
                        //showTime={{ format: 'HH:mm' }}
                        size={'large'}
                        format={"DD/MM/YYYY"}
                        style={{ width: '100%' }}
                        locale={locale}
                        disabledDate={disabledNextDate}
                    />
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default DateRangeField;