import {useState, useEffect} from 'react';
import { OperateursService } from '../../common/services';
import {Layout} from '..'

import {LoadableContent, Switch, Table, Modal} from '../../components'

import MoreToSee02 from  '../../layout/themes/images/icon/more_to_see-02.png';

import AddOperateurs from './add-operateurs/add-operateurs.component';


function  Operateurs () {
  const [isLoading, setLoading] = useState(false);
  const [operateurs, setOperateurs] = useState([]);  
  const [operateur, setOperateur] = useState(null);  
  const [forceUdate, setForceUpdate] = useState(0);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false)

    useEffect(() => {
          setLoading(true)
          OperateursService.getAll().then(setOperateurs).finally(() => setLoading(false))
      }, [forceUdate])
    
    return (
     <Layout>
      <LoadableContent {...{isLoading}}>
        <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section transactions_list" style={{minHeight : '100vh'}}>

        <div className="appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 10px'}}>
          <p data-pgc-field="appsv3-admin-bloc-title"> Liste des operateurs </p> 
          <button type="button" style={{padding: '15px 30px'}} onClick={() => {
            setOperateur(null)
            setDetailsModalOpen(true)
          }} className="btn white thin-border btn-default btn-primary virgin-button" >Ajouter</button>
        </div>
        {/* <div className="container-fluid no-padding-section">
          <p data-pgc-field="appsv3-admin-bloc-title"
            className="pull-left description">
            Consulter la liste des operateurs
          </p>
        </div> */}

          <Table 
            templates={
              [
                {title : 'Nom & Prenom', field : 'fullName', render : (operateur) =>       <div
                className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
                style={{ paddingLeft: "0PX" }}
              >
              <p className="bold margin-bottom0 margin-top0 data">
                  {`${operateur?.nom} ${operateur?.prenom}`}
                </p>
              </div>
              },
              {title : 'Email', field : 'email', render : (operateur) =>                         <div
              className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
              style={{ paddingLeft: "0PX" }}
            >
            <p className="bold margin-bottom0 margin-top0 data">
                {`${operateur?.email}`}
            </p>
            </div>
              },
              {title : 'Mobile', field : 'mobile', render : (operateur) =>  
              <div
              className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
              style={{ paddingLeft: "0PX" }}
            >
            <p className="bold margin-bottom0 margin-top0 data">
                {`${operateur?.mobile}`}
              </p>
            </div>},
            {title : 'Nom d\'utilisateur', field : 'userName', render : (operateur) =>  <div
            className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
            style={{ paddingLeft: "0PX" }}
          >
          <p className="bold margin-bottom0 margin-top0 data">
              {`${operateur?.username}`}
            </p>
          </div>},
           {title : 'Role', field : 'role', render : (operateur) =>  <div
           className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
           style={{ paddingLeft: "0PX" }}
         >
         <p className="bold margin-bottom0 margin-top0 data">
             {`${operateur?.role}`.replace("ROLE_", "")}
           </p>
         </div>},
          {title : 'Verrouillé', 
            field : 'id', 
            render : (operateur) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
              <Switch onChange={async () => {
              await OperateursService.lockUnlock(operateur.id, !operateur.locked)
              setForceUpdate(_force => _force + 1)
             }} checked={operateur.locked} />
            </div>
          },
          {title : 'Réinitialisé', 
            field : 'id', 
            render : (operateur) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
             <Switch onChange={async () => {
               if(operateur.reset) return
               await OperateursService.resetPassword(operateur.id)
               setForceUpdate(_force => _force + 1)
             }} checked={operateur.reset} />
            </div>
          },
              {title : 'Actions', field : 'id', render : (operateur) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
              <div style={{textAlign: 'center', display: 'inline-block'}}>
                  <div className="btn-group full-height bothed">
                  <button type="button" className="btn btn-default dropdown-toggle pull-right" data-toggle="dropdown" aria-expanded="false">
                      <img src={MoreToSee02} className="lazy invert" alt="" />
                  </button>
                  <ul className="dropdown-menu" role="menu">
                   <li>
                      <a className='App-link_cursor' onClick={() => {
                        setOperateur(operateur)
                        setDetailsModalOpen(true)
                        }} >Details</a>
                    </li>
                    <li>
                      <a className='App-link_cursor' onClick={async () => { 
                        await OperateursService.resetPassword(operateur.id)
                        setForceUpdate(_force => _force + 1)
                         } } >Reinitialiser</a>
                    </li>
                    <li>
                      <a className='App-link_cursor' onClick={async () => { 
                        await OperateursService.lockUnlock(operateur.id, !operateur.locked)
                        setForceUpdate(_force => _force + 1)
                         }} >{operateur.locked ? 'Deverrouiller' : 'Verrouiller'}</a>
                    </li>
                  </ul>
                  </div>
              </div>
              </div>}
              ]
              } 
            data={operateurs} 
            />
        </div>
      </LoadableContent>
      
      <Modal title={ operateur ? 'Modifier un operateur' : "Ajouter un operateur"} isOpen={isDetailsModalOpen} onClose={() => setDetailsModalOpen(false)} >
        <AddOperateurs {...{operateur}} onComplete={(isCanceled) => {
          setDetailsModalOpen(false)
          if(!isCanceled) setForceUpdate(_force => _force + 1)
        }} />
      </Modal>
     </Layout>
    )
}


export default Operateurs;