//import {  Link } from "react-router-dom";
import './not-found.page.scss'

function  NotFound () {
        return (
            <div className="page-not-found">
                <h1 className="title">
                    404
                </h1>
                <div className="desc">La page que vous recherchez n'a pas été trouvée.</div>
          <button className="go-back-btn" type="primary" size="large">Retourner</button>
            </div>
        );
        }


export default NotFound;