import { useContext, useEffect, useState, useMemo } from 'react';


import {Table, Modal, Breadcrumbs} from '../../components'
import Vue02Image from  '../../layout/themes/images/icon/vue-02-02.png';
import MoreToSee02 from  '../../layout/themes/images/icon/more_to_see-02.png';
import { AppContext } from '../../common/constants';
import { OperationsService } from '../../common/services';
import { LoadableContent } from '../../components';

import printImage from '../../layout/themes/images/icon/print-03.png'
import { NumberHelper, DateHelper } from '../../common/helper';

function Operations () {

  const {store} = useContext(AppContext)

  const [operations, setOperations] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedOperation, setSelectedOperation] = useState({});

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);  

  useEffect(()=>{
    document.addEventListener("click", closeDrawer);
    return ()=>document.removeEventListener("click", closeDrawer);
  }, [])

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  
  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])

  useEffect(() => {
      if(store?.concessionnaire?.id) {
        setLoading(true)
        OperationsService.get({debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()}, store.concessionnaire.id)
        .then(setOperations)
        .finally(() => setLoading(false))
      }
    }, [store?.concessionnaire?.id, date])
    const dynamicText = "Opérations";
    return (
      <>
      <Breadcrumbs secondLinkText={dynamicText} />
      <LoadableContent {...{isLoading}}>
        <div className="container-fluid theme-hover-bg-zoom-in animatedParent full-height theme-maincolorback-light thin-border-top sub-section" id="particuliers" style={{backgroundSize: 'contain'}}>
          <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section transactions_list">
            {/* <div className="appsv3-admin-bloc-title appsv3-form-fieldset">
              <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left"> {`Dernieres opérations pour ${store?.concessionnaire?.code}`} </p>
            </div>
            <div className="container-fluid no-padding-section">
              <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left description">Consulter l'historique des opérations du concessionnaire sélectionné</p>
            </div> */}
            <div className="appsv3-admin-bloc-title">
              <p data-pgc-field="appsv3-admin-bloc-title"> {`Dernieres opérations pour ${store?.concessionnaire?.code}`} </p>
            </div>
            <div className="container-fluid no-padding-section">
              <p data-pgc-field="appsv3-admin-bloc-title"
                className="pull-left description">
                Consulter l'historique des opérations du concessionnaire sélectionné
              </p>
            </div>
            
            <Table 
              templates={
                [
                  {title : 'Code', field : 'type', render : (item) => <div className="full-height theme-vertical-align">
                <div className="profileimg" style={{backgroundColor: '#5c1818'}}>
                    <p style={{lineHeight: '60px', textAlign: 'center'}}>{item.code}</p>
                </div>
                </div>
                },
                {title : 'Libellé', field : 'libelle', render : (item) =>  <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height" style={{paddingLeft: '0px'}}>
                <div>
                    <p className="bold margin-bottom0 margin-top0 label App-text-nowrap">{new Date(item.date).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })} {item.heure}</p>
                    <p className="bold margin-bottom0 margin-top0 data App-text-nowrap">{item.libelle}</p>
                    <span className="description hidden" />
                </div>
                </div>
                },
                {title : 'Site', field : 'site', hiddenXs: true, render : (item) =>  <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height" style={{paddingLeft: '0px', display: 'flex', alignItems: 'center'}}>
                <div>
                    <p className="bold margin-bottom0 margin-top0 data">{item.guichet}</p>
                    <span className="description hidden" />
                </div>
                </div>
                },
                {title : 'Montant', field : 'montantReelVerse', render : (item) =>  
                  <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center!important'}}>
                <p className="bold margin-bottom0 margin-top0 data prize-extented" style={{width: '100%', textAlign: 'center'}}>{NumberHelper.formatWithThousandsSeparator(item.montantReelVerse)} FCFA</p>
                </div>},
                {title : '', field : 'id', render : (item) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
                <div style={{textAlign: 'center', display: 'inline-block'}}>
                    <div className="btn-group full-height">
                    <button onClick={() => {
                      setSelectedOperation(item)
                      setDetailsModalOpen(true)
                    }} type="button" className="btn btn-default">
                        <img src={Vue02Image}  className="lazy invert" alt="" />
                    </button>
                    </div>
                </div>
                </div>}
                ]
                } 
              data={operations} />
            </div>
            </div>
            <Modal isOpen={isDetailsModalOpen} onClose={() => setDetailsModalOpen(false)} >
              <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent" style={{backgroundColor: 'transparent !important'}}>
                <div className="appsv3-admin-bloc-title  transactions_list" style={{backgroundColor: 'transparent !important'}}>
                  <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left therma">Details de la transaction</p>
                  <div className="btn-group pull-right" style={{height: 'auto', marginBottom: '0px !important'}}>
                    <a href={selectedOperation?.recu ?? ''} target='_blank' style={{backgroundColor: 'white'}} type="button" className="btn btn-default pull-right no-border">
                      <img src={printImage} className="lazy invert pull-right icon" alt=""  />
                    </a>
                  </div>
                </div>
                <div className="no-border container-fluid transactions_list">
                  <div className="row apps-line-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p>Code Opération</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                      <p className="details">{selectedOperation?.code ?? ''}</p>
                    </div>
                  </div>
                  <div className="row apps-line-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p>Réference</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p className="details">{selectedOperation?.refOperation ?? ''}</p>
                    </div>
                  </div>
                  <div className="row apps-line-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p>Date et heure</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p className="details">{new Date(selectedOperation.date).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })} - {selectedOperation?.heure ?? ''}</p>
                    </div>
                  </div>
                  <div className="row apps-line-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p>Libellé</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p className="details">{selectedOperation?.libelle ?? ''}</p>
                    </div>
                  </div>
                  <div className="row apps-line-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p>Montant</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p className="details">
                        {NumberHelper.formatWithThousandsSeparator(selectedOperation?.montantReelVerse ?? '')}
                      </p>
                    </div>
                  </div>
                  {/*<div className="row apps-line-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p>Site</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p className="details">36 Hectares</p>
                    </div>
                  </div>*/}
                  <div className="row apps-line-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p>Site</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p className="details">{selectedOperation?.site ?? ''}</p> 
                    </div>
                </div>
                </div>
                <div className="text-center section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                  <a onClick={() => setDetailsModalOpen(false)} className="btn white thin-border btn-default" >Fermer</a>
                </div>
              </div>
            </Modal>




            
      </LoadableContent>
      </>

        
    )
}


export default Operations;