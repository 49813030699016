import React, { useEffect, useState } from 'react';
import Layout from '../layout/layout.page';
import { ReportingService } from '../../common/services';
import { b64toBlob } from '../../common/helper/file.helpers';
import FileSaver from 'file-saver';
import { Button, Card, Col, Form, Pagination, Row, Segmented, Table } from 'antd';
import CustomAntdForm from '../../components/forms/custom-antd-form';
import ProductsField from '../../components/forms/fields/ProductsField';
import SitesField from '../../components/forms/fields/SiteField';
import UidField from '../../components/forms/fields/UidField';
import CodeSelectField from '../../components/forms/fields/CodeSelectField';
import { NumberHelper } from '../../common/helper';
import CodeTerminaux from '../../components/forms/fields/CodeTerminaux';
import DateRangeField from '../../components/forms/fields/DateRangeField';

const terminalCanalPayload = {
    concessionnaireId: 0,
    terminalId: 0,
    produitId: 0,
    siteId: 0,
    format: null,
    sDate: null,
    eDate: null
}

const ReportingTerminauxCanaux=()=> {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [exportType, setExportType] = useState('pdf');
    const [currentPage, setCurrentPage] = useState(1);
    const [terminaux, setTerminaux] = useState([]); 
    const [uidSelected, setUidSelected] = useState(null);
    const [terminalCanalRequest, setTerminalCanalRequest] = useState(terminalCanalPayload);
    const [codeConcessionnaireSelected, setCodeConcessionnaireSelected]= useState(null);

    const [state, setState]= useState({
        // Product
        products: [],
        isProductLoading: false,

        // Sites
        sites: [],
        isSitesLoading: false,

        isExporting: false,

        // Pagination
        pageNo: 0,
        pageSize: 0,
        totalElements: 0,
        totalPages: 0,
        last: 0
    })

    useEffect(() => {
        loadTerminaux(terminalCanalRequest, currentPage)
    }, [])

    const loadTerminaux=(request, page)=> {
        setLoading(true)
        ReportingService.getTerminauxRechargementParCanaux(request,{page: page-1, size: 10} ).then((response)=> {
            
            setState(state=> ({
                ...state,
                pageNo: response?.pageNo,
                pageSize: response?.pageSize,
                totalElements: response?.totalElements,
                totalPages: response?.totalPages,
                last: response?.last
            }))
            console.log(":::: response?.data :", response?.data)
            setTerminaux(response?.data || [])
        }).finally(() => setLoading(false));
    }

    const download=()=> {
        setState(state=> ({...state, isExporting: true}))
        ReportingService.downloadTerminauxRechargementParCanaux({...terminalCanalRequest, format: exportType})
        .then((response) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
            if(response?.bytes) {
                const b64Data = response?.bytes;
                const blob = b64toBlob(b64Data, response?.format === exportType ? 'data:application/vnd.ms-excel;' : 'application/pdf');
                FileSaver.saveAs(blob, response?.fileName)
            }
            else {
                alert("Echec de telechargement ...")
            }
        })
        .finally(() => setState(state=> ({...state, isExporting: false})));
    }

    const refreshFilter=(init=false)=> {
        if(init) {
            form.setFieldsValue({
                uid: null,
                code: null,
                produitId: null,
                siteId: null,
                codeTerminal: null,
                rangeDate: null
            })
            setTerminalCanalRequest(terminalCanalPayload)
            loadTerminaux(terminalCanalPayload, currentPage)
        }
        else {
            form.validateFields().then((values)=> {
                const rangeDate = values?.rangeDate;
                const startDate = rangeDate[0]?.format("YYYY-MM-DD");
                const endDate   = rangeDate[1]?.format("YYYY-MM-DD");
                const terminalCanalRequest = {
                    concessionnaireId: values?.code,
                    produitId: values?.produitId,
                    siteId: values?.siteId,
                    terminalId: values?.codeTerminal,
                    sDate: startDate,
                    eDate: endDate
                }
                setTerminalCanalRequest(terminalCanalRequest)
                loadTerminaux(terminalCanalRequest, currentPage)
            })
            .catch(error => {
                console.log(":::: Error dans les champs de filtre")
            })
        }
        
    }

    const onPageChange = (page) => {
        console.log(":::: onPageChange ", page)
        setCurrentPage(page);
        loadTerminaux({...terminalCanalRequest}, page);
    };
    const onShowSizeChange = (current) => {
        loadTerminaux({...terminalCanalRequest}, current);
    };

    const onChangeSegmented=(e)=> {
        setExportType(e)
    }

    const columns = [
        {
            title: "Code terminal",
            dataIndex: "numeroTerminal",
            key: "numeroTerminal",
        },
        {
            title: "Caisse LONACI",
            dataIndex: "totalCaisseLonaci",
            key: "totalCaisseLonaci",
            width: 200,
            align: 'right'
        },
        {
            title: "Caisse PROVINOV",
            dataIndex: "totalCaisseMomo",
            key: "totalCaisseMomo",
            align: 'right'
        },
        {
            title: "Total",
            dataIndex: "totaux",
            key: "totaux",
            align: 'right',
            render : (item, teriminal) => (`${NumberHelper.formatWithThousandsSeparator(teriminal.totaux)}`)
        }
    ];


    return (
        <React.Fragment>
            <Layout>
                <div className="appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 10px'}}>
                    <p data-pgc-field="appsv3-admin-bloc-title"> Liste des terminaux </p> 
                    <div style={{display:'flex', justifyContent: 'end'}}>
                        <div style={{height: '9px'}}>
                            <Segmented options={[{ label: 'PDF', value: 'pdf' }, { label: 'Excel', value: 'xls' }]} onChange={onChangeSegmented}/>
                        </div>
                        <Button  
                            onClick={() => download()} 
                            loading={state?.isExporting} 
                            style={{borderColor: '#000', margin: '0px 10px'}} 
                            type='success' 
                            size='medium' block>
                            <span style={{color: '#fff !important'}}>Exporter</span>
                            </Button>
                        {/* <button type="button" style={{padding: '15px 30px'}} onClick={() => {
                            download("xls")
                        }} className="btn white thin-border btn-default btn-success virgin-button"  >
                            {state.isLoadingXls && (<Loader className={'loadable-content__content__loader'}  style={{height:15, width: 15, marginRight: 10}} /> ) } 
                            <span style={{fontSize:12, color: '#fff'}}>XLS</span></button>

                        <button type="button" style={{padding: '15px 30px', marginLeft: '5px', marginRight:"10px"}} onClick={() => {
                            download("pdf")
                        }} className="btn white thin-border btn-default btn-success virgin-button"  >
                            {state.isLoadingPdf && (<Loader className={'loadable-content__content__loader'} style={{height:15, width: 15, marginRight: 10}} /> ) } 
                            <span style={{fontSize:12, color: '#fff'}}>PDF</span></button> */}
                    </div>
                </div>
                <Card>
                    <CustomAntdForm form={form}>
                        <Row gutter={8} >
                            <ProductsField label='Produit' md={4}/> 
                            <SitesField label={'Site'} md={4} />
                            <UidField 
                                label={'UID'}
                                onUIDSelected={setUidSelected} 
                                md={4} 
                                />
                            <CodeSelectField 
                                // placeholder={'Code concessionnaire'}
                                label={'Code concessionnaire'}
                                uuid={uidSelected}
                                onCodeSelected={setCodeConcessionnaireSelected}
                                md={4}
                                />
                            <CodeTerminaux 
                                label={'Code terminaux'}
                                placeholder={'Terminaux'}
                                codeConcessionnaire={codeConcessionnaireSelected} 
                                md={4} />
                            <DateRangeField required={true} label={('Periodes')} />

                            <Col className="gutter-row" xs={3} md={3} style={{paddingTop: 40}}>
                                <Button  onClick={() => refreshFilter()}  style={{borderColor: '#000'}} type='success' size='large' block >
                                <span style={{color: '#fff !important'}}>Rafraichir</span></Button>
                            </Col>
                            <Col className="gutter-row" xs={3} md={3} style={{paddingTop: 40}}>
                                <div style={{display: 'flex'}}>
                                    <Button  onClick={() => refreshFilter(true)}  style={{borderColor: '#000'}} type='success' size='large' block >
                                        Initialiser</Button>
                                </div>
                            </Col>
                        </Row>
                    </CustomAntdForm>
                    <Table 
                        columns={columns} 
                        dataSource={terminaux || []} 
                        loading={isLoading}
                        // pagination={{ position: ["topRight"], pageSize: state?.pageSize }}
                        pagination={false}
                        />
                    <div style={{ textAlign: 'center' }}>
                        <Pagination current={currentPage} onChange={onPageChange} defaultPageSize={10} total={state.totalElements} showSizeChanger onShowSizeChange={onShowSizeChange} />
                    </div>
                </Card>
            </Layout>
        </React.Fragment>
    )
}

export default ReportingTerminauxCanaux;