import React, { useEffect, useState } from 'react';
import Layout from '../layout/layout.page';
import { ReportingService } from '../../common/services';
import { NumberHelper } from '../../common/helper';
import { b64toBlob } from '../../common/helper/file.helpers';
import FileSaver from 'file-saver';
import { Button, Card, Col, Form, Pagination, Row, Segmented, Table } from 'antd';
import ProductsField from '../../components/forms/fields/ProductsField';
import SitesField from '../../components/forms/fields/SiteField';
import UidField from '../../components/forms/fields/UidField';
import CustomAntdForm from '../../components/forms/custom-antd-form';
import CodeSelectField from '../../components/forms/fields/CodeSelectField';
import DateRangeField from '../../components/forms/fields/DateRangeField';
import TransactionField from '../../components/forms/fields/TransactionField';
import ReferenceField from '../../components/forms/fields/ReferenceField';
import CodeTerminaux from '../../components/forms/fields/CodeTerminaux';

const rechargementPayload = {
    uidConcessionnaire: null,
    codeConcessionnaire: null,
    produitId: null,
    siteId: null,
    numeroTerminal: null,
    transactionId: null,
    reference: null,
    sDate: null,
    eDate: null
}

const ReportingRechargements=()=> {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [exportType, setExportType] = useState('pdf');
    const [currentPage, setCurrentPage] = useState(1);
    const [rechargements, setRechargements] = useState([]);  
    const [uidSelected, setUidSelected] = useState(null);
    const [rechargementsRequest, setRechargementsRequest] = useState(rechargementPayload);
    const [codeConcessionnaireSelected, setCodeConcessionnaireSelected]= useState(null);
    

    const [state, setState]= useState({
        // Product
        products: [],
        isProductLoading: false,

        // Sites
        sites: [],
        isSitesLoading: false,

        isExporting: false,

        // Pagination
        pageNo: 0,
        pageSize: 0,
        totalElements: 0,
        totalPages: 0,
        last: 0
    })

    useEffect(() => {
        loadRechargements(rechargementsRequest, currentPage)
    }, [])

    const loadRechargements=(request, page)=> {
        setLoading(true)
        ReportingService.getRechargements(request, {page: page - 1 , size: 10} ).then((response)=> {
            console.log(":::: response : ", response)
            setState(state=> ({
                ...state,
                pageNo: response?.pageNo,
                pageSize: response?.pageSize,
                totalElements: response?.totalElements,
                totalPages: response?.totalPages,
                last: response?.last
            }))
            
            setRechargements(response?.data || [])
        }).finally(() => setLoading(false));
    }

    const download=()=> {
        setState(state=> ({...state, isExporting: true}))
    
        ReportingService.downloadRechargements({...rechargementsRequest, format: exportType})
        .then((response) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
            if(response?.bytes) {
                const b64Data = response?.bytes;
                const blob = b64toBlob(b64Data, response?.format === exportType ? 'data:application/vnd.ms-excel;' : 'application/pdf');
                FileSaver.saveAs(blob, response?.fileName)
            }
            else {
                alert("Echec de telechargement ...")
            }
        })
        .finally(() => setState(state=> ({...state, isExporting: false })));
    }
    
    const refreshFilter=(init=false)=> {
        if(init) {
            form.setFieldsValue({
                uid: null,
                code: null,
                produitId: null,
                siteId: null,
                codeTerminal: null,
                transactionId: null,
                reference: null,
                rangeDate: null
            })
            setRechargementsRequest(rechargementPayload)
            loadRechargements(rechargementPayload, currentPage)
        }
        else {
            form.validateFields().then((value)=> {
                console.log(":::::: values :", value)
                const rangeDate = value?.rangeDate;
                const startDate = rangeDate[0]?.format("YYYY-MM-DD");
                const endDate   = rangeDate[1]?.format("YYYY-MM-DD");
                const rechargementRequest = {
                    uidConcessionnaire: value?.uid,
                    codeConcessionnaire: value?.code,
                    produitId: value?.produitId,
                    siteId: value?.siteId,
                    numeroTerminal: value?.codeTerminal,
                    transactionId: value?.transactionId,
                    reference: value?.reference,
                    sDate: startDate,
                    eDate: endDate
                }
                setRechargementsRequest(rechargementRequest)
                loadRechargements(rechargementRequest, currentPage)
            })
            .catch(error => {
                console.log(":::: Error dans les champs de filtre")
            })
        }
    }

    const onPageChange = (page) => {
        console.log(":::: onPageChange ", page)
        setCurrentPage(page);
        loadRechargements({...rechargementsRequest}, page);
    };
    const onShowSizeChange = (current) => {
        loadRechargements({...rechargementsRequest}, current);
    };

    const onChangeSegmented=(e)=> {
        setExportType(e)
    }

    const onChage=(e)=> {
        console.log('Code terminaux :', e)
    }

    const columns = [
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            render : (item, rechargement) => (
                <div className="full-height theme-vertical-align">
                    <div className="profileimg" style={{backgroundColor: '#5c1818', width: 50, height:50}}>
                        <p style={{textAlign: 'center'}}>{rechargement.code}</p>
                    </div>
                </div>)
        },
        {
            title: "libelle",
            dataIndex: "libelle",
            key: "libelle",
            render : (item, rechargement) =>  (
                <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height" style={{paddingLeft: '0px'}}>
                    <div>
                        <p className="bold margin-bottom0 margin-top0 label App-text-nowrap">{new Date(rechargement.date).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })} {rechargement.heure}</p>
                        <p className="bold margin-bottom0 margin-top0 data App-text-nowrap">{rechargement.libelle}</p>
                        <span className="description hidden" />
                    </div>
                </div>
            )
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
        },
        {
            title: "Montant",
            dataIndex: "montantReelVerse",
            key: "montantReelVerse",
            render: (item, rechargement)=> (`${NumberHelper.formatWithThousandsSeparator(rechargement.montantReelVerse)} FCFA`)
        }
    ];

    return (
        <React.Fragment>
            <Layout>
                <div className="appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 10px'}}>
                    <p data-pgc-field="appsv3-admin-bloc-title"> Liste des rechargements </p> 
                    <div style={{display:'flex', justifyContent: 'end'}}>
                        <div style={{height: '9px'}}>
                            <Segmented size='large' options={[{ label: 'PDF', value: 'pdf' }, { label: 'Excel', value: 'xls' }]} onChange={onChangeSegmented}/>
                        </div>
                        <Button  
                            onClick={() => download()} 
                            loading={state?.isExporting} 
                            style={{borderColor: '#000', margin: '0px 10px'}} 
                            disabled={rechargementsRequest?.sDate === null || rechargementsRequest?.eDate === null ||  rechargementsRequest?.produitId === null ||  rechargementsRequest?.siteId === null }
                            type='success' 
                            size='large' block>
                            <span style={{color: '#fff !important'}}>Exporter</span>
                            </Button>
                        {/* <button type="button" style={{padding: '15px 30px'}} onClick={() => {
                            download("xls")
                        }} className="btn white thin-border btn-default btn-success virgin-button"  >
                            {state.isLoadingXls && (<Loader className={'loadable-content__content__loader'}  style={{height:15, width: 15, marginRight: 10}} /> ) } 
                            <span style={{fontSize:12, color: '#fff'}}>XLS</span></button>

                        <button type="button" style={{padding: '15px 30px', marginLeft: '5px', marginRight:"10px"}} onClick={() => {
                            download("pdf")
                        }} className="btn white thin-border btn-default btn-success virgin-button"  >
                            {state.isLoadingPdf && (<Loader className={'loadable-content__content__loader'} style={{height:15, width: 15, marginRight: 10}} /> ) } 
                            <span style={{fontSize:12, color: '#fff'}}>PDF</span></button> */}
                    </div>
                </div>
                <Card>
                    <CustomAntdForm form={form}>
                        <Row gutter={8} >
                            <ProductsField required={true} label={'Produit'} md={4}/> 
                            <SitesField label={'Sites'} md={4}/>
                            <UidField label={'UID'} onUIDSelected={setUidSelected}/>
                            <CodeSelectField 
                                label='Code concessionnaire'
                                placeholder='Selectionner code'
                                uuid={uidSelected}
                                onCodeSelected={setCodeConcessionnaireSelected}
                                disable={true}
                                md={4}/>
                            <CodeTerminaux 
                                label={'Code terminaux'}
                                placeholder={'Selectionner un terminal'}
                                codeConcessionnaire={codeConcessionnaireSelected} 
                                md={4} />
                            <TransactionField  label='Transaction' md={4}/>
                            <ReferenceField label={'Reference'} md={4} />
                            <DateRangeField required={true} label={('Periodes')} />

                            <Col className="gutter-row" xs={3} md={3} style={{paddingTop: 40}}>
                                <div style={{display: 'flex'}}>
                                    <Button  onClick={() => refreshFilter()}  style={{borderColor: '#000'}} type='success' size='large' block >
                                        Rafraichir</Button>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={3} md={3} style={{paddingTop: 40}}>
                                <div style={{display: 'flex'}}>
                                    <Button  onClick={() => refreshFilter(true)}  style={{borderColor: '#000'}} type='success' size='large' block >
                                        Initialiser</Button>
                                </div>
                            </Col>
                        </Row>
                    </CustomAntdForm>
                    <Table 
                        columns={columns} 
                        dataSource={rechargements || []} 
                        loading={isLoading}
                        // pagination={{ position: ["topRight"], pageSize: state?.pageSize }}
                        pagination={false}
                        />
                    <div style={{ textAlign: 'center' }}>
                        <Pagination current={currentPage} onChange={onPageChange} defaultPageSize={10} total={state.totalElements} showSizeChanger onShowSizeChange={onShowSizeChange} />
                    </div>
                </Card>
            </Layout>
        </React.Fragment>
    )
}

export default ReportingRechargements