import {createContext} from 'react';

//export const API_BASE_URL = 'http://localhost:8081';
export const API_BASE_URL = 'https://api.mylonaci.com';
//export const API_BASE_URL = 'https://preprod-api.mylonaci.com';

export const APP_NAME = "BO_APP";

export const LOCAL_STORAGE_KEYS = {
   ACCESS_TOKEN : 'accessToken',
   CURRENT_USER : 'currentUser'
}

export const USER_ROLE = {
   OPERATEUR : "ROLE_OPERATEUR",
   ADMIN : "ROLE_ADMIN"
}

export const ACCESS_TOKEN = 'accessToken';

export const CURRENT_USER = 'currentUser';

export const AuthContext = createContext('AUTH_CONTEXT');

export const AppContext = createContext('APP_CONTEXT');

// PROFILE/Action
export const CAN_VIEW_CLIENTS = ["ROLE_ADMIN", "ROLE_SUPERVISEUR"];
export const CAN_VIEW_OPERATORS = ["ROLE_ADMIN" ];
export const CAN_EXPORT_CONCESSIONNAIRE = ["ROLE_ADMIN", "ROLE_SUPERVISEUR", "ROLE_AGENCE", "ROLE_SIEGE"];
export const CAN_EXPORT_TERMINAUX=["ROLE_ADMIN", "ROLE_SUPERVISEUR", "ROLE_AGENCE", "ROLE_SIEGE"];
export const CAN_EXPORT_RECHARGEMENTS=["ROLE_ADMIN", "ROLE_SUPERVISEUR", "ROLE_AGENCE", "ROLE_SIEGE"];
export const CAN_EXPORT_CHIFFRE_AFFAIRE= ["ROLE_ADMIN", "ROLE_SUPERVISEUR", "ROLE_AGENCE", "ROLE_SIEGE"];
export const CAN_EXPORT_RECHARGEMENT_PAR_CANAUX=["ROLE_ADMIN", "ROLE_SUPERVISEUR", "ROLE_AGENCE", "ROLE_SIEGE"];



