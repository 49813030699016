import {useState, useEffect} from 'react';
import { ClientsService } from '../../common/services';
import {Layout} from '..'

import { useNavigate } from 'react-router-dom';

import {LoadableContent, Switch, Table, Modal} from '../../components'
import MoreToSee02 from  '../../layout/themes/images/icon/more_to_see-02.png';

import AddClients from './add-clients/add-clients.component';


function  Clients () {
  const [isLoading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);  
  const [client, setClient] = useState(null);  
  const [forceUdate, setForceUpdate] = useState(0);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false)

  const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        ClientsService.getAll().then(setClients).finally(() => setLoading(false))
    }, [forceUdate])
    
    return (
     <Layout>
      <LoadableContent {...{isLoading}}>
        <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section transactions_list" style={{minHeight : '100vh'}}>

        <div className="appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 10px'}}>
          <p data-pgc-field="appsv3-admin-bloc-title"> Liste des clients </p> 
          <button type="button" style={{padding: '15px 30px'}} onClick={() => {
            setClient(null)
            setDetailsModalOpen(true)
          }} className="btn white thin-border btn-default btn-primary virgin-button" >Ajouter</button>
        </div>
        {/* <div className="container-fluid no-padding-section">
          <p data-pgc-field="appsv3-admin-bloc-title"
            className="pull-left description">
            Consulter la liste des clients
          </p>
        </div> */}

          <Table 
            templates={
              [
                {title : 'Nom & Prenom', field : 'fullName', render : (client) =>       <div
                className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
                style={{ paddingLeft: "0PX" }}
              >
              <p className="bold margin-bottom0 margin-top0 data">
                  {`${client?.nom} ${client?.prenom}`}
                </p>
              </div>
              },
              {title : 'Email', field : 'email', render : (client) =>                         <div
              className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
              style={{ paddingLeft: "0PX" }}
            >
            <p className="bold margin-bottom0 margin-top0 data">
                {`${client?.email ?? ''}`}
            </p>
            </div>
              },
              {title : 'Mobile', field : 'mobile', render : (client) =>  
              <div
              className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
              style={{ paddingLeft: "0PX" }}
            >
            <p className="bold margin-bottom0 margin-top0 data">
                {`${client?.mobile}`}
              </p>
            </div>},
            {title : 'Nom d\'utilisateur', field : 'username', render : (client) =>  <div
            className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
            style={{ paddingLeft: "0PX" }}
          >
          <p className="bold margin-bottom0 margin-top0 data">
              {`${client?.username}`}
            </p>
          </div>},
          {title : 'Verrouillé', 
            field : 'id', 
            render : (client) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
              <Switch onChange={async () => {
              await ClientsService.lockUnlock(client.id, !client.locked)
              setForceUpdate(_force => _force + 1)
             }} checked={client.locked} />
            </div>
          },
          {title : 'Réinitialisé', 
            field : 'id', 
            render : (client) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
             <Switch onChange={async () => {
               if(client.reset) return
               await ClientsService.resetPassword(client.id)
               setForceUpdate(_force => _force + 1)
             }} checked={client.reset} />
            </div>
          },
              {title : 'Actions', field : 'id', render : (client) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
              <div style={{textAlign: 'center', display: 'inline-block'}}>
                  <div className="btn-group full-height bothed">
                  <button type="button" className="btn btn-default dropdown-toggle pull-right" data-toggle="dropdown" aria-expanded="false">
                      <img src={MoreToSee02} className="lazy invert" alt="" />
                  </button>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <a className='App-link_cursor' onClick={() => {
                        setClient(client)
                        setDetailsModalOpen(true)
                        }} >Details</a>
                    </li>
                    <li>
                      <a className='App-link_cursor' onClick={() => navigate(`/dashboard/${client.lcaisseId}`, {state : client})} >Naviguer comme</a>
                    </li>
                    <li>
                      <a className='App-link_cursor' onClick={async () => { 
                        await ClientsService.resetPassword(client.id)
                        setForceUpdate(_force => _force + 1)
                         } } >Reinitialiser</a>
                    </li>
                    <li>
                      <a className='App-link_cursor' onClick={async () => { 
                        await ClientsService.lockUnlock(client.id, !client.locked)
                        setForceUpdate(_force => _force + 1)
                         }} >{client.locked ? 'Deverrouiller' : 'Verrouiller'}</a>
                    </li>
                  </ul>
                  </div>
              </div>
              </div>}
              ]
              } 
            data={(clients || []).map((client)=> ({...client, fullName: `${client.nom} ${client.prenom}`}))} 
            />
        </div>
      </LoadableContent>
      
      {isDetailsModalOpen && <Modal title={ client ? 'Modifier un client' : "Ajouter un client"} isOpen={isDetailsModalOpen} onClose={() => {
          setClient(null)
          setDetailsModalOpen(false)
        }} >
        <AddClients {...{client}} onComplete={(isCanceled) => {
          setDetailsModalOpen(false)
          if(!isCanceled) setForceUpdate(_force => _force + 1)        
        }} />
      </Modal>}
     </Layout>
    )
}


export default Clients;