import { Col, Form, Select, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import SelectWithFilter from '../select-with-filter-component';
import { ConcessionnaireService } from '../../../common/services';

const CodeSelectField=({uuid, onCodeSelected, label, placeholder, required=false, disable=false, size='large', xs, md})=> {
    const { Option } = Select;
    const [state, setState]= useState({
        codes: [],
        isCodeLoading: false
    })

    useEffect(()=> {
        if(uuid) {
            loadCodes(uuid)
        }
        else {
            setState(state=>({...state, codes:  []}))
        }
    }, [uuid])

    const loadCodes=(uid)=> {
        setState(state=>({...state, isCodeLoading: true}))
        ConcessionnaireService.get(uid).then((data)=> {
            let _data = !required ? [{id: null, code: 'Tous les codes'}, ...(data || [])] :  data || [];
            setState(state=>({...state, codes: _data}))
        })
        .finally(() => setState(state=>({...state, isCodeLoading: false})));  
    }

    const handleCodeSelected=(value)=> {
        if(onCodeSelected) {
            onCodeSelected(value)
        }
    }

    
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item 
                    label={label ?? ''}
                    style={{ borderColor: "#fff002" }}
                    name='code'
                    rules={[{ required: required, message: "Champ obligatoire" }]}
                >
                    <SelectWithFilter 
                        placeholder={placeholder ?? ''} 
                        size={size} 
                        //disabled={disable}
                        suffixIcon={state.isCodeLoading ? (<Space><div>Chargement...</div><Spin size='small' /></Space>) : null}
                        onChange={handleCodeSelected}
                    >
                        {state.codes.map((code, index)=> (<Option key={index} value={code?.id}>{code?.code}</Option>))}  
                    </SelectWithFilter>
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default CodeSelectField;