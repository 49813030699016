import { API_BASE_URL, LOCAL_STORAGE_KEYS, APP_NAME} from '../constants';
import {
    redirect
  } from "react-router-dom";

const request = async (options) => {

    const headers = options.contentType ? new Headers(options.contentType) : new Headers();
    if(localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN))
    }

    if(APP_NAME) headers.append('App-Name', APP_NAME)

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    
    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                json = Object.assign({}, {status : response.status}, json);
                return Promise.reject(json);
            }
            return json;
        }).catch(parseError => {
            console.log(":::: Request error : ", parseError)
            //throw parseError;
        })
    );
};

export function handleLogout() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_USER);
    throw redirect("/login");
  }

export async function exeRequest(relativeUrl, method, data, abort = null, isFile=false){

    let options = {
        url: API_BASE_URL + relativeUrl,
        method: method,
        signal : abort?.signal ?? null,
        contentType:  !isFile ? {'Content-Type':  'application/json'} : null
    }

    if(data && data !== {}){
        options = Object.assign({}, {body :  !isFile ? JSON.stringify(data) : data}, options);
    }

    console.log("Options:::: ", options)

    try{
    return await request(options)
    }
    catch(error)  {
        if(error.status === 401) {
            handleLogout('/login');    
        } 
        else if(error.status === 403) {
            /*notification.error({
                message: 'GeoCRM App',
                description: "Vous n'avez pas les autorisations necessaire pour effectuer cette operation ..."
            });*/
            alert("Vous n'avez pas les autorisations necessaire pour effectuer cette operation ...")
            
        }else{ 
            console.log(":::: Request error 2 : ", error)
            throw error;                 
        }
    };
}