import React, { useEffect, useState } from 'react';
import { Col, Form, Select, Space, Spin } from 'antd';
import SelectWithFilter from '../select-with-filter-component';
import { ConcessionnaireService, ReportingService } from '../../../common/services';

const CodeTerminaux=({codeConcessionnaire, label, placeholder,required = false, disable=false, size='large', xs, md})=> {

    const { Option } = Select;
    const [state, setState]= useState({
        terminaux: [],
        isTerminauxLoading: false
    })

    useEffect(()=> {
        if(codeConcessionnaire) {
            loadTerminaux(codeConcessionnaire)
        }
        
    }, [codeConcessionnaire])

    const loadTerminaux=(code)=> {
        setState(state=>({...state, isTerminauxLoading: true}))
        ReportingService.getTermianuxByCodeConcessionnaure({id: code}).then((data)=> {
            console.log(":::: Codes terminaux:", data)
            let _data = !required ? [{id: null, numero: 'Tous les terminaux'}, ...(data || [])] :  data || [];
            setState(state=>({...state, terminaux: _data }))
        })
        .finally(() => setState(state=>({...state, isTerminauxLoading: false})));  
    }

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item 
                    label={label ?? ''}
                    name='codeTerminal'
                    style={{ borderColor: "#fff002" }}
                    rules={[{ required: required, message: "Champ obligatoire" }]}
                >
                    <SelectWithFilter 
                        placeholder={placeholder ?? ''} 
                        size={size} 
                        //disabled={disable}
                        suffixIcon={state.isTerminauxLoading ? (<Space><div>Chargement...</div><Spin size='small' /></Space>) : null}
                    >
                        {state.terminaux.map((terminal, index)=> (<Option key={index} value={terminal?.id}>{terminal?.numero}</Option>))}  
                    </SelectWithFilter>
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default CodeTerminaux;