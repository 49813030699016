import {exeRequest} from '../utils/APIUtils'

export default {
    // DATA
    getConcessionnaires : async  (data, options) => exeRequest(`/concessionnaires/list?page=${options?.page}&size=${options?.size}`, "POST", data),
    getTerminaux : async  (data, options) => exeRequest(`/terminaux/list?page=${options?.page}&size=${options?.size}`, "POST", data),
    getTerminauxRechargementParCanaux : async  (data, options) => exeRequest(`/terminaux/rechargement-canaux?page=${options?.page}&size=${options?.size}`, "POST", data),
    getTermianuxByCodeConcessionnaure: async  (options) => exeRequest(`/terminaux/concessionnaire?id=${options?.id}`, "GET"),
    getRechargements : async  (data, options) => exeRequest(`/operations/list?page=${options?.page}&size=${options?.size}`, "POST", data),
    getChiffreAffairess : async  (data, options) => exeRequest(`/chiffre-affaires/list?page=${options?.page}&size=${options?.size}`, "POST", data),

    // Export
    downloadConcessionnaires: async  (data) => exeRequest(`/reporting/concessionnaires`, "POST", data),
    downloadTerminaux       : async  (data) => exeRequest(`/reporting/terminaux`, "POST", data),
    downloadTerminauxRechargementParCanaux : async  (data, options) => exeRequest(`/reporting/rechargement-canaux`, "POST", data),
    downloadRechargements   : async  (data) => exeRequest(`/reporting/operations`, "POST", data),
    downloadChiffreAffaires   : async  (data) => exeRequest(`/reporting/chiffre-affaires`, "POST", data),
}