
import { useCallback, useEffect, useMemo, useState } from 'react';

import Search02Image from  '../../layout/themes/images/icon/search-02.png';
import Filter02Image from  '../../layout/themes/images/icon/filter-02.png';
import printImage from '../../layout/themes/images/icon/print-03.png'

import { NoResults } from '../';

import { usePagination } from '../../common/hooks';

import './table.component.scss'

const Table = ({templates, data, pageSize = 10, noResults = "" }) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [dataFiltered, setDataFiltered] = useState([])
    const totalPageCount = useMemo(() => Math.ceil(dataFiltered?.length / pageSize), [dataFiltered])

    const {paginationRange, DOTS} = usePagination({
      currentPage : page,
      totalCount : dataFiltered?.length,
      siblingCount : 0,
      pageSize
    }); 


    const onFilter = useCallback(()  =>{
        let _data = [...data]
      
        if(searchTerm) {
          _data = _data?.filter(item => templates.some(template => item[template.field]?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase())))
        }
        setDataFiltered(_data);
      } , [data, searchTerm]);

      useEffect(()=>{
        onFilter();
      }, [onFilter])

    return (
        <div className='mylonaci-table'>
        <div className="container-fluid no-padding-section" data-pgc="search_filter" >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: 0 }}>
                <div className="bg-transparent margin-bottom0 container-fluid amen no-padding-section" data-pgc="search-block-amen"
                data-pgc-field="search-block-amen"
                style={{
                    paddingLeft: "0px !important",
                    backgroundColor: "transparent !important",
                    borderRadius: 24
                }}
                >
                <div
                    style={{ backgroundColor: "transparent !important", height: 60 }}
                    className="searchform"
                >
                    <div className="container-fluid full-height" style={{ padding: 0 }}>
                    <div className="hero-area-form full-height">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 full-height no-padding-section">
                        <input
                            value={searchTerm}
                            autoFocus=""
                            type="text"
                            placeholder="Effectuer une recherche"
                            name="s"
                            pattern=".{3,}"
                            title="3 characters minium"
                            onChange={(event) => setSearchTerm(event.target.value)}
                            style={{
                            boxShadow: "none",
                            fontWeight: "bold",
                            marginRight: "-27px",
                            border: "solid 1px #eee",
                            width: "100%",
                            paddingLeft: 25,
                            }}
                            className="no-shadow form-control ellipsis theme-maincolorback-light thin-border full-height"
                        />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="container-fluid no-padding-section">
        <div className="container-fluid" style={{padding: '0px'}}>
            <div>
            <div>
                <div className="no-border">
                <div>
                    <table className="table" border={0}>
                    <thead>
                        <tr>
                        {templates?.map((template, key) => <th key={`th-${key}`}>{template.title ?? ''}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {dataFiltered?.length ? dataFiltered?.slice((page - 1) * pageSize, page * pageSize)?.map((item, key) => <tr key={`tr-${key}`}>
                            {templates?.map((template, key) => <td className={`${template.hiddenXs?'hidden-xs': ''}`} key={`tr-${key}-td-${key}`}>{template.render ? template.render(item) : item[template.field]}</td>)}
                        </tr>) :
                        (<tr>
                            <td colSpan={templates.length}>
                              <NoResults className='myLonaci-ro-results' {...{noResults}}  />
                          </td>
                          </tr>)
                        }
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

        {totalPageCount > 1 && <div className="container-fluid text-center no-padding-section">
        <ul className="pagination">
            <li>
                <a className={`mylonaci-table__button${page === 1 ? '-disabled' : ''}`} onClick={() => setPage(_page =>_page - 1)}>«</a>
            </li>

         {paginationRange?.map(pageNumber => {
              // If the pageItem is a DOT, render the DOTS unicode character
              /*if (pageNumber === DOTS) {
                return <li className="pagination-item dots">&#8230;</li>;
              }*/
          
              // Render our Page Pills
              return (
                <li
                className={`${ pageNumber  === page ? 'active' : ''}`}
                >
                  <a  onClick={() => setPage(pageNumber)} className={`mylonaci-table__button${pageNumber === DOTS ? '-disabled' : ''} ${ pageNumber === page ? 'w' : ''}`}> {pageNumber} {pageNumber === page && <span className="sr-only">(current)</span>}</a>
                </li>
              );
            })}
            <li>
                <a className={`mylonaci-table__button${page === totalPageCount ? '-disabled' : ''}`}  onClick={() => setPage(_page =>_page + 1)} >»</a>
            </li>
        </ul>
        </div>}
        </div>

        
    )
}

export default Table