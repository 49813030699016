import React, { useEffect, useState } from 'react';
import Layout from '../layout/layout.page';
import { Loader } from '../../components';
import { ReportingService } from '../../common/services';
import FileSaver from 'file-saver';
import { b64toBlob } from '../../common/helper/file.helpers';
import { Button, Card, Col, Form, Pagination, Row, Segmented, Table } from 'antd';
import SitesField from '../../components/forms/fields/SiteField';
import UidField from '../../components/forms/fields/UidField';
import CodeField from '../../components/forms/fields/CodeField';
import ProductsField from '../../components/forms/fields/ProductsField';
import NomField from '../../components/forms/fields/NomField';
import PrenomField from '../../components/forms/fields/PrenomField';
import EmailField from '../../components/forms/fields/EmailField';
import CustomAntdForm from '../../components/forms/custom-antd-form';
import './style.css';

const concessionnairePayload={
    uid: null, 
    code: null, 
    nom: null, 
    prenom: null, 
    email: null, 
    produitId: 0,
    siteId: 0
};

const ReportingConcessionnaires=()=> { 
    const [isLoading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [exportType, setExportType] = useState('pdf');
    const [concessionnaires, setConcessionnaires] = useState([]);
    const [concessionnaireRequest, setConcessionnaireRequest] = useState(concessionnairePayload);
    const [form] = Form.useForm();

    const [state, setState]= useState({
        // Product
        products: [],
        isProductLoading: false,

        // Sites
        sites: [],
        isSitesLoading: false,

        isExporting: false,

        // Pagination
        pageNo: 0,
        pageSize: 0,
        totalElements: 0,
        totalPages: 0,
        last: 0
    })

    useEffect(() => {
        loadConcessionnaires(concessionnaireRequest, currentPage)
    }, [])

    // useEffect(()=> {
    //     loadConcessionnaires(concessionnaireRequest)
    //     console.log("::::: PAGE COURANT:", currentPage)
    // }, [currentPage])

    // Charge liste des concessionnaires
    const loadConcessionnaires=(request, page)=> {
        setLoading(true)
        ReportingService.getConcessionnaires(request,{page: page - 1, size: 10} ).then((response)=> {
            console.log(":::: response : ", response)
            setState(state=> ({
                ...state,
                pageNo: response?.pageNo,
                pageSize: response?.pageSize,
                totalElements: response?.totalElements,
                totalPages: response?.totalPages,
                last: response?.last
            }))
            //setConcessionnairesReponse({data: response?.data, pageNo: response?.pageNo, pageSize: response?.pageSize,  })
            setConcessionnaires(response?.data || [])
        }).finally(() => setLoading(false));
    }

    const download=()=> {
        setState(state=> ({...state, isExporting: true}))
        ReportingService.downloadConcessionnaires({...concessionnaireRequest, format: exportType})
        .then((response) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
            if(response?.bytes) {
                const b64Data = response?.bytes;
                const blob = b64toBlob(b64Data, response?.format === exportType ? 'data:application/vnd.ms-excel;' : 'application/pdf');
                FileSaver.saveAs(blob, response?.fileName)
            }
            else {
                alert("Echec de telechargement ...")
            }
        })
        .finally(() => setState(state=> ({...state, isExporting: false})));
    }

    const refreshFilter=(init=false)=> {
        if(init) {
            form.setFieldsValue({
                uid: null, 
                code: null, 
                nom: null, 
                prenom: null, 
                email: null, 
                produitId: null,
                siteId: null
            })
            setConcessionnaireRequest(concessionnairePayload)
            loadConcessionnaires(concessionnairePayload, currentPage)
        }
        else {
            form.validateFields().then((value)=> {
                console.log(":::::: values :", value)
                const concessionnaireRequest = {
                    uid: value?.uid, 
                    code: value?.code, 
                    nom: value?.nom, 
                    prenom: value?.prenom, 
                    email: value?.email, 
                    produitId: value?.produitId,
                    siteId: value?.siteId
                }
                setConcessionnaireRequest({...concessionnaireRequest})
                loadConcessionnaires({...concessionnaireRequest}, currentPage)
            })
            .catch(error => {
                console.log(":::: Error dans les champs de filtre")
            })
        }
        
    }

    const onPageChange = (page) => {
        console.log(":::: onPageChange ", page)
        setCurrentPage(page);
        loadConcessionnaires({...concessionnaireRequest}, page);
    };
    const onShowSizeChange = (current) => {
        loadConcessionnaires({...concessionnaireRequest}, current);
    };

    const columns = [
        {
            title: "UID",
            dataIndex: "uid",
            key: "uid",
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "Nom",
            dataIndex: "nom",
            key: "nom",
        },
        {
            title: "Prénoms",
            dataIndex: "prenom",
            key: "prenom",
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Produit",
            dataIndex: "produit",
            key: "produit",
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
        }

    ];

    const onChangeSegmented=(e)=> {
        setExportType(e)
    }

    return (
        <React.Fragment>
            <Layout>
                <div className="appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 10px'}}>
                    <p data-pgc-field="appsv3-admin-bloc-title"> Liste des concessionnaires </p> 
                    <div style={{display:'flex', justifyContent: 'end'}}>
                        <div style={{height: '9px'}}>
                            <Segmented size='large' options={[{ label: 'PDF', value: 'pdf' }, { label: 'Excel', value: 'xls' }]} onChange={onChangeSegmented}/>
                        </div>
                        <Button  
                            onClick={() => download()}  
                            loading={state?.isExporting}
                            style={{borderColor: '#000', margin: '0px 10px'}} 
                            type='success' 
                            size='large' block>
                            <span style={{color: '#fff !important'}}>Exporter</span>
                            </Button>
                        {/* <button type="button" style={{padding: '15px 30px'}} onClick={() => {
                            download("xls")
                        }} className="btn white thin-border btn-default btn-success virgin-button"  >
                            {state.isLoadingXls && (<Loader className={'loadable-content__content__loader'}  style={{height:15, width: 15, marginRight: 10}} /> ) } 
                            <span style={{fontSize:12, color: '#fff'}}>XLS</span></button>

                        <button type="button" style={{padding: '15px 30px', marginLeft: '5px', marginRight:"10px"}} onClick={() => {
                            download("pdf")
                        }} className="btn white thin-border btn-default btn-success virgin-button"  >
                            {state.isLoadingPdf && (<Loader className={'loadable-content__content__loader'} style={{height:15, width: 15, marginRight: 10}} /> ) } 
                            <span style={{fontSize:12, color: '#fff'}}>PDF</span></button> */}
                    </div>
                </div>
                <Card>
                    <CustomAntdForm form={form}>
                        <Row gutter={8} >
                            <ProductsField label={"Produit"} /> 
                            <SitesField label={"Site"} />
                            <UidField label={"UID"} />
                            <CodeField label={"Code concessionnaire"} />
                            <NomField label={"Nom"} />
                            <PrenomField label={"Prénoms"} />
                            {/* <EmailField label={"Email"} /> */}
                            <Col className="gutter-row"  md={3} style={{paddingTop: 40}}>
                                <Button  onClick={() => refreshFilter()}  style={{borderColor: '#000'}} type='success' size='large' block>
                                    <span style={{color: '#fff !important'}}>Rafraichir</span></Button>
                            </Col>
                            <Col className="gutter-row" xs={3} md={3} style={{paddingTop: 40}}>
                                <div style={{display: 'flex'}}>
                                    <Button  onClick={() => refreshFilter(true)}  style={{borderColor: '#000'}} type='success' size='large' block >
                                        Initialiser</Button>
                                </div>
                            </Col>
                        </Row>
                    </CustomAntdForm>

                    <Table 
                        columns={columns} 
                        dataSource={concessionnaires || []} 
                        loading={isLoading}
                        // pagination={{ position: ["topRight"], pageSize: state?.pageSize }}
                        pagination={false}
                        />
                    <div style={{ textAlign: 'center' }}>
                        <Pagination current={currentPage} onChange={onPageChange} defaultPageSize={10} total={state.totalElements} showSizeChanger onShowSizeChange={onShowSizeChange} />
                    </div>
                </Card> 
            </Layout>
        </React.Fragment>
    )
}

export default ReportingConcessionnaires