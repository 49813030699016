import { Button, Col, Form, Input} from 'antd';
import React from 'react';
const UidField=({label, onUIDSelected, disable=false, size='large', xs, md})=> {

    const onUIDChange=(event)=> {
        console.log("UUID Change ", event?.target.value)
        if(onUIDSelected && event?.target.value) {
            onUIDSelected(event?.target.value)
        }
    }

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item 
                    label={label ?? ''}
                    name="uid"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    <Input 
                        placeholder='Saisir UID' 
                        size={size} 
                        disabled={disable} 
                        onBlur={onUIDChange}/>
                    
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default UidField;