
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../common/constants';

import { Link } from 'react-router-dom';
import OrangeMoneyLogo from '../../layout/themes/images/partners/orangemoneylogo.jpg';
import MomoLogo  from '../../layout/themes/images/partners/mtn-momo.png';
import MoovLogo  from '../../layout/themes/images/partners/moov-money.png';
import WaveLogo  from '../../layout/themes/images/partners/wave-logo.png';
import SuccessImage from '../../layout/themes/images/icon/success-02.png'
import EchecImage from '../../layout/themes/images/icon/echec.png'
import { UseAuthentification } from '../../common/hooks';
import {RechargementService, TerminalService} from '../../common/services'
import { NumberHelper } from '../../common/helper';
import { Breadcrumbs } from '../../components';





const Rechargement = () => {

  const auth = UseAuthentification();
  const {store} = useContext(AppContext);
  const [currentTab, setCurrentTab] = useState(0)
  const [terminaux, setTerminaux] = useState([]);
  const [data, setData] = useState({terminal : '', amount : null, phone : null, typeMomo: ''})
  const [response, setResponse] = useState(null);



  useEffect(() => {
    if(store?.concessionnaire?.id) {
      //setLoading(true)
      TerminalService.get(store.concessionnaire.id)
      .then(setTerminaux)
      //.finally(() => setLoading(false))
    }
  }, [store?.concessionnaire?.id])


  const dynamicText = "Rechargement";
    return (
      
    <>
     <Breadcrumbs secondLinkText={dynamicText} />
      <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" style={{paddingTop: '10px !important'}}>
  <div className="appsv3-admin-bloc-title" style={{paddingLeft: '12px'}}>
    <p data-pgc-field="appsv3-admin-bloc-title">Effectuer un rechargement</p> 
  </div>
  <div className="container-fluid sub-section">
    <div className="no-padding-section col-md-6">
      <div className="container-fluid theme-maincolorback-light sub-section">
        <form id="regForm" action style={{marginTop: '0px', width: '100%', marginBottom: '0px', backgroundColor: 'transparent', paddingTop: '0px', paddingBottom: '0px'}} className="no-padding-section">
          {/* One "tab" for each step in the form: */}
          <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 0 ? 'block' : 'none'}}>
            <div className="container-fluid no-padding-section">
              <h3>Etape 1 / 2 : Saisie des informations de rechargement</h3>
              <p>Renseignez les informations relatives au rechargement</p>
            </div>
            <div className="container-fluid no-padding-section">
              <p>
                <select value={data.terminal?.id} onChange={(event) => event.target.value && setData(_data => ({..._data, terminal : terminaux.find(ter => ter.id?.toString() === event.target.value)}))} className="form-control">
                    <option value="">Sélectionnez un terminal</option>                                                             
                    {terminaux.map(terminal => <option value={terminal.id}>{terminal.numero}</option>  )}                                                               
                </select>
              </p>
              <p>
                <select value={data.typeMomo} className="form-control" onChange={(event) => event.target.value && setData(_data => ({..._data, typeMomo : event.target.value}))}>
                    <option value="">Sélectionnez votre mobile</option>                                                             
                     <option value="ORANGE Money">ORANGE Money</option>                                                           
                     <option value="MTN Money">MTN Money</option>                                                           
                     <option value="MOOV Money">MOOV Money</option>                                                           
                     <option value="WAVE">WAVE </option>                                                           
                     <option value="VISA Africards">VISA Africards </option>                                                           
                </select>
              </p>
              <p>
                <input placeholder="Entrez le numero de telephone" value={data.phone} onChange={(event) => setData(_data => ({..._data, phone : event.target.value}))} className="form-control" />
              </p>
              <p>
                <input placeholder="Entrez le montant" 
                value={data.amount} onChange={(event) => setData(_data => ({..._data, amount : event.target.value}))} className="form-control" />
              </p>    
            </div>
            <div style={{overflow: 'auto'}} className="virgin-button">
              <div style={{float: 'right'}}>
                <button type="button"  className="btn white thin-border btn-default btn-primary virgin-button" onClick={() =>{
                  if(!data.amount || !data.phone || !data.typeMomo) return;
                  setCurrentTab(currentTab + 1)
                  }}  >Suivant</button>
              </div>
            </div>
          </div>
          <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 1 ? 'block' : 'none'}}>
            <div className="container-fluid no-padding-section">
              <h3>Étape 2 / 2 : Confirmation des informations de rechargement</h3>
              <p>Verifiez les informations saisies et confirmez le rechargement</p>
            </div>
            <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent thin-border" style={{backgroundColor: 'transparent !important'}}>
              <div id="loaded" className="container-fluid" />
              <div className="container-fluid no-padding-section">
                <div className="appsv3-admin-bloc-title container-fluid sub-section transactions_list" style={{backgroundColor: 'transparent !important'}}>
                  <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left">Résumé</p> 
                </div>
                <div className="no-border container-fluid transactions_list">
                  <div className="row apps-line-data">
                    <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p style={{ fontSize: '2em !important' }}>Terminal</p> 
                      </div>                                                                     
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                    <p className="details">{`${data.terminal?.numero} (${store?.concessionnaire?.code})`}</p>
                    </div>
                  </div>
                  <div className="row apps-line-data">
                    <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p>Telephone</p> 
                      </div>                                                                     
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                      <p className="details">{`${data.phone}`}</p> 
                    </div>
                  </div>
                  <div className="row apps-line-data">
                    <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5">
                      <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <p>Montant</p> 
                      </div>                                                                     
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p className="details">{data.amount}</p> 
                    </div>
                  </div>
                </div>
                <div className="virgin-button text-right sub-section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                  <button type="button" onClick={() => setCurrentTab(currentTab - 1)}  className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} >Precedent</button>
                  <button type="button" 
                  onClick={async() => {
                    try{
                    const response = await RechargementService.rechargeMomo({...data,terminal: data.terminal.id});
                    setResponse(response);
                    setCurrentTab(currentTab + 1)
                    }catch{
                      setCurrentTab(currentTab + 2)
                    }
                  }} 
                  className="btn white thin-border btn-default btn-primary virgin-button launch_transaction" >
                    Valider
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 2 ? 'block' : 'none'}}>
            <div className="full-height section container-fluid" style={{}}>
              <div className="container-fluid" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {response?.operatorId === '' ? (
                  <img src={EchecImage} style={{verticalAlign: 'middle', display: 'inline-block',height: '50px'}} data-pgc-field="loading_div_icon" className="bounceIn clearfix" />
                ) : (
                  <img src={SuccessImage} style={{verticalAlign: 'middle', display: 'inline-block'}} data-pgc-field="loading_div_icon" className="bounceIn clearfix" />
                )}
              </div>
              <br />
              <h3 className="clearfix text-center">
                {response?.operatorId === '' ? "Echec Transaction" : "Transaction réussie"}
              </h3>
              <center style={{fontSize : '13px', marginTop : '15px'}}>
                {response && response.msg}
              </center>
              <div className="virgin-button text-center section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                <Link className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} to="/dashboard" >Fermer</Link>
                <button style={{ height: '45px'}} type="button" onClick={() => {
                  setData({terminal : '', amount : 0});
                  setCurrentTab(0);
                }} className="btn white thin-border btn-default btn-primary virgin-button launch_transaction">
                  Effectuer une autre transaction
                </button>
              </div>
            </div>
          </div>

          <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 3 ? 'block' : 'none'}}>
          <div className="full-height section container-fluid" style={{}}>
            <div className="container-fluid" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={EchecImage} style={{verticalAlign: 'middle', display: 'inline-block', height: '50px'}} data-pgc-field="loading_div_icon" className="bounceIn clearfix" />
            </div>
            <br />
            <h3 className="clearfix text-center">Echec Transaction</h3>
            <center style={{fontSize : '13px', marginTop : '15px'}}>Veuillez composer le #144*43*2418#, choisir paiement, puis inserer votre numero Orange Money pour valider votre paiement.</center>
            <div className="virgin-button text-center section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
              <Link className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} to="/dashboard" >Fermer</Link>
              <button style={{ height: '45px'}} type="button" onClick={() => {
                setData({terminal : '', amount : 0});
                setCurrentTab(0)
                }} className="btn white thin-border btn-default btn-primary virgin-button launch_transaction">Effectuer une autre transaction
              </button>
            </div>
          </div>
          {/* console.log(res); */}
          </div>
          {/* Circles which indicates the steps of the form: */}
          <div style={{textAlign: 'center', marginTop: '40px'}}>
            <span className={`step ${currentTab === 0 ? 'active' : ''}`} />
            <span className={`step ${currentTab === 1 ? 'active' : ''}`} />
            <span className={`step ${currentTab === 2 ? 'active' : ''}`} />
          </div>
        </form>
      </div>                                     
    </div>
    <div className="col-md-6 text-center sub-section">
      <h4 className="theme-text-thin">Paiement par mobile money <br />via la plateforme LonaciPay.</h4>
      <div className="container-fluid" style={{alignItems: 'center', justifyContent: 'space-between !important'}}>
        <img src={OrangeMoneyLogo} style={{width: '80px', height: '60px'}} />
        <img src={MomoLogo} style={{width: '70px', height: '50px', marginRight: '4px'}} />
        <img src={MoovLogo} style={{width: '80px',height: '50px', marginRight: '4px'}} />
        <img src={WaveLogo} style={{width: '80px',height: '40px'}} />
      </div>                                     
    </div>
  </div>
        </div>
    </>

    )
}

export default Rechargement;