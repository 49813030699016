import { useContext, useEffect, useState,useMemo, useCallback } from 'react';
import { AppContext } from '../../common/constants';
import { TerminalService } from '../../common/services';
import { LoadableContent, NoResults, Loader } from '../../components';
import { useNavigate } from 'react-router-dom';

import Green02Image from '../../layout/themes/images/icon/green_set_ph-02.png';
import Terminal01Image from '../../layout/themes/images/icon/terminal-01.png';
import Vue02Image from '../../layout/themes/images/icon/vue-02-02.png';
import { NumberHelper, DateHelper } from '../../common/helper';

const Termminal = () => {
  const { store } = useContext(AppContext);
  const [terminaux, setTerminaux] = useState([]);
  const [soldes, setSoldes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  
  const [soldesLoading, setSoldesLoading] = useState(false)
  const navigate = useNavigate();
  
  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])

  useEffect(() => {
    if(store?.concessionnaire?.id) {
      setLoading(true)
      TerminalService.get(store.concessionnaire.id, {debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()})
      .then(setTerminaux)
      .finally(() => setLoading(false))

      setSoldesLoading(true)
      TerminalService.getSoldes(store.concessionnaire.id)
      .then(setSoldes)   
      .finally(() => setSoldesLoading(false))

    }
  }, [store?.concessionnaire?.id])

  const displaySolde = useCallback((terminal) => {
    const updatedSolde = soldes?.find(solde => solde.numero === terminal.numero)
   if(updatedSolde?.montant) return NumberHelper.formatWithThousandsSeparator(updatedSolde.montant);
   const montantSolde =  isNaN(terminal?.solde) ? terminal?.solde?.montant :  terminal?.solde;
   return `${NumberHelper.formatWithThousandsSeparator(montantSolde ?? 0)}${terminal?.solde?.date ? ' (au ' + DateHelper.format(terminal?.solde?.date)  + ')': ''}`
  }, [soldes]) 

  return (
    <LoadableContent isLoading={isLoading}>
      <div className="container-fluid theme-hover-bg-zoom-in animatedParent full-height theme-maincolorback-light thin-border-top sub-section" id="particuliers" style={{ backgroundSize: 'contain' }} data-pgc-define="SELECT_TERMINAL" data-pgc-define-name="SELECT_TERMINAL">
        <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section transactions_list">
          <div className="appsv3-admin-bloc-title appsv3-admin-bloc-title_2">
            <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left">
              {`Liste des terminaux pour ${store?.concessionnaire?.code}`}
            </p>
          </div>
          <div className="container-fluid no-padding-section">
            <div className="col-xs-12 sub-section col-sm-2 no-padding-section dc-desc col-lg-3 col-md-3">
              <div className="container-fluid no-padding-section full-height">
                <div className="col-md-12 col-lg-12 no-padding-section full-height theme-vertical-align col-xs-4 col-sm-4 desc-pict">
                  <div className="container-fluid">
                    <p>
                      <img src={Green02Image} className="preview_icon" alt="" style={{ marginLeft: 'auto', marginRight: 'auto', width: '80% !important' }} width="100%!important" />
                    </p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xs-8 col-sm-8 no-padding-section full-height theme-vertical-align desc-desc grayscale">
                  <div>
                    <h4>Selectionner un terminal</h4>
                    <p>Veuillez choisir un terminal afin d’afficher plus de détail</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-lg-9 col-md-9 col-sm-12 no-padding-section">
              {terminaux.length ?  terminaux?.map((terminal) => (
                <div key={terminal?.id} className="container-fluid terminal-list" data-pgc-define="terminal_list" data-pgc-define-name="terminal_list">
                  <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-1 col-md-1 col-xs-2 col-sm-2" style={{ paddingLeft: '0px', backgroundColor: '#efce70' }}>
                    <div className="full-height theme-vertical-align bloc" data-pgc-edit="5555[class, style, no_content]">
                      <img src={Terminal01Image} className="preview_icon invert" alt="" />
                    </div>
                  </div>
                  <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-3 col-md-3 col-sm-4 col-xs-4" style={{ paddingLeft: '0px', backgroundColor: '#efce70' }} data-pgc-edit="aaaaa[class, style, no_content]">
                    <div className="bloc">
                      <p className="bold margin-bottom0 margin-top0 label">TERMINAL No</p>
                      <p className="bold margin-bottom0 margin-top0 data theme-text-bold name">{terminal?.numero ?? 0}</p>
                      <span className="description hidden" />
                    </div>
                  </div>
                  <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-3 col-md-3 bloc thin-border-right col-sm-4 col-xs-4" style={{ paddingLeft: '0px', backgroundColor: '#fbf1d2' }} data-pgc-edit="bbbb[class, style, no_content]">
                    <div className="bloc">
                      <p className="bold margin-bottom0 label">SOLDE (FCFA)</p>
                      <p className="bold margin-bottom0 margin-top0 data-big">
                      {displaySolde(terminal)} {soldesLoading && <Loader style={{width : '15px', height : '15px'}} />}
                      </p>
                      <span className="description hidden" />
                    </div>
                  </div>
                  <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-3 col-md-3 thin-border-right hidden-xs hidden-sm col-sm-3 col-xs-3" style={{ paddingLeft: '0px' }} data-pgc-edit="ccc[class, style, no_content]">
                    <div className="bloc">
                      <p className="bold margin-bottom0 margin-top0 label">CHIFFRE D'AFFAIRE</p>
                      <p className="bold margin-bottom0 margin-top0 data-big">
                        {NumberHelper.formatWithThousandsSeparator(terminal?.chiffreAffaire ?? 0)}
                      </p>
                      <span className="description hidden" />
                    </div>
                  </div>
                  <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ paddingLeft: '0px' }} data-pgc-edit="werwe[class, style, no_content]">
                    <div className="bloc text-center">
                      <button onClick={() => navigate(`terminal/${terminal.id}`, { state: terminal })} type="button" className="btn btn-default">
                        <img src={Vue02Image} className="lazy invert load_details" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              )) : <NoResults className='myLonaci-ro-results'  message="vous n'avez pas de terminaux prépayés" />}
            </div>
          </div>
        </div>
      </div>
    </LoadableContent>
  );
};

export default Termminal;
