
import { useEffect, useMemo, useState } from 'react';

import './App.scss';

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import { UseAuthentification } from './common/hooks';
import { UserService } from './common/services';
import { AuthContext, AppContext } from './common/constants';

import {Profil, MainLayout, Login, Operations, ChiffreAffaire, Commissions, Rechargements, Terminal, TerminalDetails, Consultation, Clients, Operateurs} from './pages';
import Operation from './pages/consultation/operation.component';
import { DateHelper } from './common/helper';
import ReportingConcessionnaires from './pages/reportings/reporting-concessionnaires.component';
import ReportingTerminaux from './pages/reportings/reporting-terminaux.component';
import ReportingRechargements from './pages/reportings/reporting-rechargements.component';
import ReportingChiffreAffaire from './pages/reportings/reporting-chiffre-affaire-concessionnaire';
import ReportingTerminauxCanaux from './pages/reportings/reporting-terminaux-canaux.component';

function App() {

const [appStore, setAppState]  = useState({month : DateHelper.getMonthAndYear()});

const auth = UseAuthentification();

const dispatcher = (newState) => setAppState(_state => ({..._state, ...newState}))



const [timeout, setTimeoutId] = useState(null);

// useEffect(() => {
//   const logout = () => {
//     // Mettre ici le code de déconnexion de l'utilisateur
//     auth.onLogout();
//   };

//   const timer = setTimeout(logout, 5 * 60 * 1000);

//   setTimeoutId(timer);

//   // Nettoyer le délai d'attente lors du démontage du composant ou lorsque le délai doit être réinitialisé
//   return () => {
//     clearTimeout(timeout);
//   };
// }, []);

const routers = useMemo(() => createBrowserRouter([
  {
    path: "/dashboard/:id",
    element: <MainLayout />,
    errorElement: <div>Not found</div>,
    children: [
      {
        path: "",
        element: <Terminal />,
      },
      {
        path: "terminal/:id",
        element: <TerminalDetails />,
      },
      {
        path: "operations",
        element: <Operations />,
      } ,
      {
        path: "chiffres-affaires",
        element: <ChiffreAffaire />,
      },
      {
        path: "commissions",
        element: <Commissions />,
      },
      {
        path: "rechargements",
        element: <Rechargements />,
      },
      {
        path: "consultations",
        element: <Consultation />,
      },
      {
        path: "consultations-operation",
        element: <Operation />,
      }
      
    ],
    loader : async () =>  {
      try{
      return await UserService.getUserInfo()
      }catch(error){
        auth.onLogout();
      }
    }
  },
  {
    path: "/",
    element: <Clients />,
    errorElement: <div>Not found</div>,
    loader : async () =>  {
      try{
      return await UserService.getUserInfo()
      }catch(error){
        auth.onLogout();
      }
    }
  },
  {
    path: "/operateurs",
    element: <Operateurs />,
    errorElement: <div>Not found</div>,
    loader : async () =>  {
      try{
      return await UserService.getUserInfo()
      }catch(error){
        auth.onLogout();
      }
    }
  },
  // REPORTING
  {
    path: "/reportings/concessionnaires",
    errorElement: <div>Not found</div>,
    element: <ReportingConcessionnaires />,
  },
  {
    path: "/reportings/terminaux",
    errorElement: <div>Not found</div>,
    element: <ReportingTerminaux />,
  },
  {
    path: "/reportings/rechargements",
    errorElement: <div>Not found</div>,
    element: <ReportingRechargements />,
  },
  {
    path: "/reportings/chiffre-affaires",
    errorElement: <div>Not found</div>,
    element: <ReportingChiffreAffaire />,
  },
  {
    path: "/reportings/terminaux/rechargement-canaux",
    errorElement: <div>Not found</div>,
    element: <ReportingTerminauxCanaux />,
  },
  {
    path: "/profil",
    element: <Profil />,
    errorElement: <div>Not found</div>
  },
  {
  path: "/login",
  element: <Login />,
  errorElement: <div>Not found</div>,
  }
]), []);

return (
    <div className="App">
      <AuthContext.Provider value={auth}>
        <AppContext.Provider value={{store : appStore, dispatcher}}>
         <RouterProvider router={routers} />
         </AppContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
