import { Outlet, Link } from 'react-router-dom';

import { Layout } from '..';


import Header from './header/Header'



import MobileMenu from './mobile-menu.page';


function  MainLayout () {
  

return (

<Layout>

<Header />
<Outlet />

<MobileMenu />
{/*<footer className="footer-bottom" ><center>@Copyright LONACI 2023</center></footer>*/}
</Layout>
    )
}

export default MainLayout;