import { useState, useContext, useEffect } from 'react';
import {
  useNavigate, useSearchParams 
} from "react-router-dom";

import { UserService } from '../../common/services';
import { AuthContext } from '../../common/constants'; 
import { Button, LoadableContent } from '../../components'; 


import './login.page.scss'


import LeftArrow from '../../layout/themes/images/icon/left-long-arrow-03.png';
import Logo from '../../layout/themes/images/my_lonaci_logo.png';
import LogoLonaci from '../../layout/themes/images/lonaci_logo.png';
//import RegisterLogo from '../../layout/themes/components/apps/images/register-login-add-on.png'


function Login () {
  
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [isForgettenPassword, setForgettenPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");


  const [data, setData] = useState({username : '', password : ''})

  const onLogin  = (response) => {
    response?.reset && setMustChangePassword(response?.reset);
    auth.onLogin(response, !response?.reset && navigate);
  }

  useEffect(() => {
    if(searchParams.get("email") && searchParams.get("temp") && !isLoading) {
      setLoading(true);
      UserService.resetPassword({email : searchParams.get("email"), temp : searchParams.get("temp")})
      .then(() =>setMessage("Votre compte a été reinitialisé avec succès, verifiez vos mails pour vos nouveaux accès"))
      .finally(() =>{ 
        setSearchParams({})
        setLoading(false)
      })
  }
  }, [])

    return (
<div className="row login-page fullheight full-height" data-pgc-field="app-content" style={{backgroundImage: 'url("images/backlogin.jpg")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
  <div className="row gc-login-1 full-height" style={{padding: '0px', margin: '0px'}} data-pgc="chimplogin" data-pgc-field="chimplogin">
    <div className="gc-login-part login-connectpart col-sm-6 animated col-xs-12 col-md-5 col-lg-5 section bg-offwhite zoomIn theme-maincolorback-light theme-font-Ubuntu" id="logthree" style={{overflow: 'auto', backgroundColor: '#fff', animationDuration: '0.3s!important'}}>
      <div className="container-fluid" style={{lineHeight: '100%', padding: '10px', textAlign:'left'}}>
        <a href="/" target="_self">
          <img src={LeftArrow} style={{width: '10%'}} className="invert hidden-xs hidden-sm" />
        </a>
        <a href="/" target="_self" className="hidden-md hidden-lg">
          <img src={LeftArrow} style={{width: '20%'}} />
        </a>
      </div>
      <LoadableContent {...{isLoading}}>
      {isForgettenPassword ? <ForgetPassword onBack={() => setForgettenPassword(false)} /> : mustChangePassword ? <FormChangePassword onLogin={onLogin}  {...{ connexionData : data}} /> : <FormLogin onForgettenPassword={() => setForgettenPassword(true)} onLogin={onLogin} {...{ data, setData}} message={message} /> }
      </LoadableContent>
    </div>
    <div className="col-sm-6 col-xs-12 col-md-7 col-lg-7 hidden-xs" style={{height: '100%', padding: '0px', overflow: 'hidden', backgroundSize: 'cover', backgroundPosition: 'bottom'}} data-animation-pro-1 data-animation-pro-trigger-1="hover" data-animation-pro-target-1=".pretty-effect" data-animation-pro-easing-1="ease-in-out" data-animation-pro-init-state-1="opacity:1;transform:scale(1);" data-animation-pro-step-1-1="transform:scale(2);opacity:0.5;" data-animation-pro-auto-update-1="true" data-animation-pro-hover-out-step-1-1="opacity:1;transform:scale(1);">
      <div style={{height: '100%', opacity: '0.3'}} className="bg-black">
      </div>
      <div className="full-height" style={{position: 'absolute', zIndex: 1000, top: 0}}>
        <div className="animated fadeInRight vertical-align:center full-height align-vertical-middle text-left col-lg-8 col-md-8 col-sm-8 col-xs-8" style={{padding: '0px', height: '100%'}} data-animation-pro-trigger-1 data-animation-pro-target-1 data-animation-pro-trigger-2="load" data-animation-pro-target-2 data-animation-pro-trigger-3 data-animation-pro-target-3 data-animation-pro-3 data-animation-pro-auto-update-3="false" data-animation-pro-init-state-1>
          <div className="coming-soon text-left animated full-height" style={{width: '100%', ineHeight: '100%', padding: '30px', paddingRight: '30%', paddingTop: '30%'}}>
            <div className="col-md-12" style={{height: '25%'}}> </div>
            <div className="col-md-12" style={{height: '40%'}}>
              <h1 style={{color: '#fff', fontSize: '6em'}} className="hidd hidden">Locator</h1>
              <h2 style={{color: 'white', fontWeight: 100}} className="theme-text-medium theme-text-thin">Gérer, Consulter, Recharger..</h2>
              <a href="#logthree" style={{fontSize: '45px'}} className="visible-xs hidden-lg hidden-md hidden-sm">
                <img src="#" width="120px" />
                <span className="glyphicon glyphicon-chevron-down hidden" />
              </a>                                     
            </div>
            <div className="col-md-12" style={{height: '30%'}}> </div>
            {/* /.row / counter */}
          </div>                             
        </div>
      </div>                     
    </div>
  </div>
</div>
    )
}

function FormLogin ({data, setData, message, onLogin, onForgettenPassword}) {
  const [errors, setErrors] = useState(null);
  const [isLoading, setLoading] = useState(false);
  return (
    <div className="container-fluid pad-bottom0 sub-section log_session transition-soft theme-vertical-align">
    <div className="container-fluid" style={{width: '100%'}}>
      <div className="container-fluid section sub-section animated fadeInUp animated-very-fast" data-pgc="outlook_block_title" data-pgc-field="outlook_block_title_bloc" style={{paddingLeft: '0px !important', paddingBottom: '0px !important'}}>
        <img src={Logo} width="130px !important"  />
      </div>
      <div className="container-fluid section sub-section animated fadeInUp animated-very-fast" data-pgc="outlook_block_title" data-pgc-field="outlook_block_title_bloc" style={{paddingLeft: '0px !important'}}>
        <h2 className="margin-top0" style={{paddingBottom: '0px', marginBottom: '0px'}}>Connectez-vous</h2>
      </div>
      <div className="gc-form-data row animated fadeInUp animated-fast" data-pgc="gc-form-data" style={{marginTop: '1%', opacity: '0.8'}}>
        <div style={{padding: '0px'}} data-pgc="gc-apps-common-login" className="container-fluid">
          <div className="form-group" data-pgc="gc-form-dataform-group" style={{ marginTop: '10px', textAlign: 'left'}}>
            <label className="control-label" htmlFor="formInput11" data-pgc-field="title" style={{ marginLeft: '15px'}}>Email, Téléphone, ou Nom d'utilisateur</label>
            <input value={data.login} type="email" className={`form-control ${errors ? 'login-page__input-invalid':''}`} data-pgc="gc-form-input" data-pgc-field="input" placeholder="Entrez email, téléphone, ou Nom d'utilisateur"  onChange={({target}) => {
               setErrors(null)
               setData(data => ({...data, username : target.value}))
               }}/>
          </div>
          <div className="form-group" style={{marginTop: '10px', textAlign: 'left'}}>
            <label className="control-label" htmlFor="formInput11" style={{ marginLeft: '15px'}}>Mot de passe</label>
            <input value={data.password} type="password" className={`form-control ${errors ? 'login-page__input-invalid':''}`}  placeholder="...." onChange={({target}) => { 
              setErrors(null)
              setData(data => ({...data, password : target.value}))
          }} />
          </div>
          {errors && (<div className="form-group login-page__errors">
          { errors?.message ?? ''}
          </div>)}   
          {message && (<div className="form-group login-page__success">{message ?? ''}</div>)}            
          <div className="virgin-button" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
            <Button className="btn btn-default virgin-button" >Annuler</Button>
            <Button className="white thin-border btn-primary" {...{isLoading}} onClick={async () => { 
              try{
              setLoading(true)
              const response = await UserService.signin(data);
              if(response?.status === '1')
               onLogin(response)
              else
              setErrors(response)
              }finally {
                setLoading(false)
              }
              }}>se connecter</Button>
          </div>
          <a href="#" onClick={onForgettenPassword} className='App-link'><p style={{marginTop: '15px'}}>Mot de passe oublié ?</p></a>
          <a href="#">
            <img src={LogoLonaci} width="140px!important" style={{marginTop: '28px'}} />
          </a>
        </div>
      </div>
    </div>                         
  </div>   
  )
}

function FormChangePassword ({ connexionData, onLogin}) {
  const [data, setData] = useState({newPassword : '', confirmNewPassword : ''})
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  return (
    <div className="container-fluid pad-bottom0 sub-section log_session transition-soft theme-vertical-align">
    <div className="container-fluid" style={{width: '100%'}}>
      <div className="container-fluid section sub-section animated fadeInUp animated-very-fast" data-pgc="outlook_block_title" data-pgc-field="outlook_block_title_bloc" style={{paddingLeft: '0px !important', paddingBottom: '0px !important'}}>
        <img src={Logo} width="130px !important"  />
      </div>
      <div className="container-fluid section sub-section animated fadeInUp animated-very-fast" data-pgc="outlook_block_title" data-pgc-field="outlook_block_title_bloc" style={{paddingLeft: '0px !important'}}>
        <h2 className="margin-top0" style={{paddingBottom: '0px', marginBottom: '0px'}}>Changement de mot de passe</h2>
      </div>
      <div className="gc-form-data row animated fadeInUp animated-fast" data-pgc="gc-form-data" style={{marginTop: '1%', opacity: '0.8'}}>
        <div style={{padding: '0px'}} data-pgc="gc-apps-common-login" className="container-fluid">

         <div className="form-group" style={{marginTop: '10px', textAlign: 'left'}}>
            <label className="control-label" htmlFor="formInput11" style={{ marginLeft: '15px'}}>Mot de passe</label>
            <input value={data.newPassword} type="password" className={`form-control ${errors ? 'login-page__input-invalid':''}`}  placeholder="...." onChange={({target}) => { 
              setData(data => ({...data, newPassword : target.value}))
          }} />
          </div>

          <div className="form-group" style={{marginTop: '10px', textAlign: 'left'}}>
            <label className="control-label" htmlFor="formInput11" style={{ marginLeft: '15px'}}>Confirmer le mot de passe</label>
            <input value={data.confirmNewPassword} type="password" className={`form-control ${errors ? 'login-page__input-invalid':''}`}  placeholder="...." onChange={({target}) => { 
              setData(data => ({...data, confirmNewPassword : target.value}))
          }} />
          </div>
          {data.newPassword  && data.confirmNewPassword && data.newPassword !== data.confirmNewPassword && (<div className="form-group login-page__errors">
            Les mots de passe ne sont pas identiquent
          </div>)}      
          {errors && (<div className="form-group login-page__errors">
            Une erreur est survenue lors de la modification de votre mot de passe
          </div>)}     
          <div className="virgin-button" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
            <Button className="white thin-border btn-primary" {...{isLoading}} onClick={async () => { 
              try{
              setLoading(true)
               await UserService.changePassword({oldPassword : connexionData.password, newPassword : data.newPassword, confirmNewPassword : data.confirmNewPassword});
               onLogin(await UserService.signin({username : connexionData.username, password : data.newPassword}));
              }
              catch(error){
                setErrors({message : error.message})
              }
              finally {
                setLoading(false)
              }
              }}>Changer</Button>
          </div>
          <a href>
            <img src={LogoLonaci} width="140px!important" style={{marginTop: '28px'}} />
          </a>
        </div>
      </div>
    </div>                         
  </div>   
  )
}

function ForgetPassword ({onBack}) {
  const [data, setData] = useState({email : ''})
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  return (
    <div className="container-fluid pad-bottom0 sub-section log_session transition-soft theme-vertical-align">
    <div className="container-fluid" style={{width: '100%'}}>
      <div className="container-fluid section sub-section animated fadeInUp animated-very-fast" data-pgc="outlook_block_title" data-pgc-field="outlook_block_title_bloc" style={{paddingLeft: '0px !important', paddingBottom: '0px !important'}}>
        <img src={Logo} width="130px !important" />
      </div>
      <div className="container-fluid section sub-section animated fadeInUp animated-very-fast" data-pgc="outlook_block_title" data-pgc-field="outlook_block_title_bloc" style={{paddingLeft: '0px !important'}}>
        <h2 className="margin-top0" style={{paddingBottom: '0px', marginBottom: '0px'}}>Mot de passe oublié</h2>
      </div>
      <div className="gc-form-data row animated fadeInUp animated-fast" data-pgc="gc-form-data" style={{marginTop: '1%', opacity: '0.8'}}>
        <div style={{padding: '0px'}} data-pgc="gc-apps-common-login" className="container-fluid">

         <div className="form-group" style={{marginTop: '10px', textAlign: 'left'}}>
            <label className="control-label" htmlFor="formInput11" style={{ marginLeft: '15px'}}>Email, Téléphone, ou Nom d'utilisateur</label>
            <input value={data.email} type="text" className={`form-control`}  placeholder="...." onChange={({target}) => { 
              setData(data => ({...data, email : target.value}))
          }} />
         </div>
         {response && (<div className="form-group login-page__success"> {response.message} </div>)}     
         <div className="virgin-button" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
            <Button className="btn btn-default virgin-button" onClick={onBack} >Retour</Button>
            <Button className="white thin-border btn-primary" {...{isLoading}} onClick={async () => { 
              try{
              setLoading(true)
              setResponse(await UserService.forgetPassword({email : data.email, link : `${window.location.origin}/login`}))
              }
              finally {
                setLoading(false)
              }
              }}>Valider</Button>
          </div>
       </div>
       </div>
      </div>
     </div>
     )

}


export default Login;