import { useEffect, useState } from "react"

import {LoadableContent} from '../'
 

import './search.component.scss'


const Search = ({searchTerm, onChange, onSelect, asyncSearch, selectedItems, disabled}) => {
   const [results, setResults] = useState([]);
   const [isLoading, setLoading] = useState(false);
   const [controller, setController] = useState(null);

   useEffect(() => {
    (async () =>  {
        try{
        setResults([])
        setLoading(true)
        if(controller) controller.abort();
        const _controller = new AbortController();
        setController(_controller);
        searchTerm?.length > 1 && setResults(await asyncSearch(searchTerm, _controller))
        }finally{
        setLoading(false)
        }
    })()
   }, [searchTerm])



    return (
        <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
              <input  
                className='form-control'
                placeholder='Recherche terminaux'
                value={searchTerm}
                disabled={disabled}
                onChange={({ target }) => onChange?.(target.value)} />
            </div>
            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" style={{maxHeight : '200px', overflowY : 'scroll', }}>
            <LoadableContent {...{isLoading}}>
                {!!results.length && <ul className="search-items-list">
                    { results?.filter(result => !selectedItems.some(sResult => result.value === sResult.value))?.map(result => (
                        <li className="search-items-list-item" key={result.value} onClick={() => {
                            if(!selectedItems.some(x => x.value === result.value)){
                                 onSelect([...selectedItems, result])
                                 onChange("")
                            }
                        }}>
                            <div>{result.label}</div>
                            <div>{result.description}</div>
                        </li>
                    ))}
                </ul> }
              </LoadableContent>
            </div>
            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" style={{marginTop : '30px'}}>
              {!!selectedItems?.length && <table style={{width : '100%'}}>
                    <tbody>
                    {selectedItems.map(item => <tr key={item.value} style={{height : '30px'}}>
                    <td>{item.value}</td>
                    <td>{item.label}</td>
                    <td>{item.description}</td>
                    {!disabled && <td> <a href="#" onClick={() => onSelect(selectedItems?.filter(sitem => sitem.value !== item.value))}>supprimer</a></td>}
                    </tr>)}
                    </tbody>  
                </table>}
            </div>
        </div>
    )
}

export default Search;