import { Col, Form, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { ProduitService } from '../../../common/services';
import SelectWithFilter from '../select-with-filter-component';

const ProductsField=({label, disable=false, required=false, size='large', xs, md})=> {
    const { Option } = Select;
    const [state, setState]= useState({
        // Product
        products: [],
        isProductLoading: false
    })

    useEffect(()=> {
        loadProduits()
    }, [])

    // Charge liste des produits
    const loadProduits=()=> {
        setState(state=>({...state, isProductLoading: true}))
        ProduitService.getProducts().then((data)=> {
            let _data = !required ? [{id: null, libelle: 'Tous les produits'}, ...(data || [])] :  data || [];
    
            setState(state=>({...state, products: _data}))
        })
        .finally(() => setState(state=>({...state, isProductLoading: false})));
    }

    // const onProductSelected=(e)=> {
    //     setConcessionnaireRequest({...concessionnaireRequest, produitId: e?.target?.value })
    // }

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item 
                    label={label ?? ''}
                    name="produitId"
                    rules={[{ required: required, message: "Champ obligatoire" }]}
                >
                    {state?.isProductLoading ? (<Spin />) : (
                        <SelectWithFilter 
                            placeholder='Produit' size={size} disable={disable}> 
                            {state.products.map((product, index)=> (<Option key={index} value={product?.id}>{product?.libelle}</Option>))}                                              
                        </SelectWithFilter>
                    )}
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default ProductsField;