import { Col, Form, Input } from 'antd';
import React from 'react';

const TransactionField=({label, disable=false, size='large', xs, md})=> {
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item 
                    label={label ?? ''}
                    name="transactionId"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    <Input placeholder='Transaction' size={size} disabled={disable}/>
                    
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default TransactionField;